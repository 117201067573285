import SessionModalEmptyState from '../../../SessionModalEmptyState';

interface SessionTranscriptTabProps {
  transcript: any[];
}

interface Message {
  at: string;
  text: string;
}

interface GroupedMessages {
  user: string;
  messages: Message[];
}

const SessionTranscriptTab = (props: SessionTranscriptTabProps) => {
  const { transcript } = props;

  const groupMessagesByAuthor = () => {
    const groupedMessages: GroupedMessages[] = [];

    let currentGroup: GroupedMessages | null = null;

    transcript?.forEach((message) => {
      if (!currentGroup || currentGroup.user !== message.user) {
        // Start a new group if the user changes
        if (currentGroup) {
          groupedMessages.push(currentGroup);
        }
        currentGroup = { user: message.user, messages: [message] };
      } else {
        // Add to the current group if the user is the same
        currentGroup.messages.push(message);
      }
    });

    // Push the last group to the result
    if (currentGroup) {
      groupedMessages.push(currentGroup);
    }

    return groupedMessages;
  };

  const groupedMessages = groupMessagesByAuthor();

  return (
    <div className="flex flex-col gap-2">
      {!transcript?.length ? (
        <SessionModalEmptyState />
      ) : (
        groupedMessages.map((group, groupIndex) => (
          <div key={groupIndex} className="flex">
            <p className="text-white text-xs font-bold w-1/5">{group.user}</p>
            <div className="flex flex-col gap-2 w-4/5">
              {group.messages.map((msg, msgIndex) => (
                <div key={msgIndex} className="flex gap-6">
                  <p className="text-customGrey text-xs">{msg.at}</p>
                  <p className="text-white text-xs leading-5">{msg.text}</p>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SessionTranscriptTab;
