import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/happy-calendar.svg';
import { useFeedbackMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Spin, Typography } from 'antd';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import MeetingNeedingFeedbackMentor from './MeetingNeedingFeedbackMentor';

type Props = {
  isMentor?: boolean;
};

const MeetingsNeedingFeedback = ({ isMentor }: Props) => {
  const { data, isLoading, isError, refetch } = useFeedbackMeetings();

  const [viewAll, setViewAll] = useState(false);

  if (isLoading) {
    return <Spin />;
  }
  if (isError) {
    return (
      <Typography.Paragraph style={{ color: COLORS.TEXT_PRIMARY }}>
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  if (!data) {
    return (
      <EmptyStateCard
        icon={<EmptyStateIcon />}
        className="mb-6"
        text={
          <>
            Great job! All feedback is up to date. <br />
            Timely feedback helps your students stay on track and improve.
          </>
        }
      />
    );
  }

  return (
    <div className={style.meetings}>
      {!viewAll &&
        data?.map((meeting: any, index) =>
          index < 5 ? (
            <div key={meeting?._id}>
              <MeetingNeedingFeedbackMentor
                meeting={meeting}
                refetch={refetch}
                isMentor={isMentor}
              />
            </div>
          ) : null
        )}

      {viewAll &&
        data?.map((meeting: any, index) => (
          <div key={meeting._id}>
            <MeetingNeedingFeedbackMentor
              meeting={meeting}
              refetch={refetch}
              isMentor={isMentor}
            />
          </div>
        ))}

      {data && data?.length > 5 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              font: 'normal normal medium 13px/16px Urbane Rounded',
              fontSize: '13px',
              color: COLORS.PRIMARY,
              alignSelf: 'flex-end',
            }}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View less' : 'View all'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default MeetingsNeedingFeedback;
