import { Meeting } from '@shared/common';
import SessionModalEmptyState from '../../../SessionModalEmptyState';
import SessionMeeting from './SessionMeeting';

interface SessionMeetingsTabProps {
  meetings: Meeting[];
  name: string;
}

const SessionMeetingsTab = (props: SessionMeetingsTabProps) => {
  const { meetings, name } = props;

  return (
    <div>
      <div className="flex flex-col gap-1">
        {!meetings?.length ? (
          <SessionModalEmptyState />
        ) : (
          meetings?.map((meeting) => (
            <SessionMeeting name={name} key={meeting._id} meeting={meeting} />
          ))
        )}
      </div>
    </div>
  );
};

export default SessionMeetingsTab;
