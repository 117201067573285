import { ReactComponent as EditIcon } from '@assets/icons/V4/edit-icon.svg';
import { ReactComponent as NoDataIcon } from '@assets/icons/V4/no-data.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/V4/trash-icon.svg';
import IconButton from '@components/V4/IconButton';
import Table from '@components/V4/Table/Table';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import moment from 'moment';
import { useContext, useState } from 'react';
import SessionModalSection, {
  ActionButton,
} from '../../../../SessionModalSection';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';
import UniversitySectionForm from './UniversitySectionForm';

interface UniversitySectionProps extends BasicProps {
  universities?: any[];
}

const UniversitySection = (props: UniversitySectionProps) => {
  const { universities } = props;
  const [isAddingUniversity, setIsAddingUniversity] = useState(false);
  const [universityToEdit, setUniversityToEdit] = useState<any>();

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [showToast] = useToast();

  const { mutate: updateUserProfile } = useSaveUserProfile(studentId, {
    onSuccess: () => {
      showToast({
        variant: 'info',
        messageBody: 'University deleted successfully.',
      });
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageBody: 'University could not be deleted.',
      });
    },
  });

  const universityButtons: ActionButton[] = [
    {
      buttonText: 'Add University',
      onClick: () => setIsAddingUniversity(true),
    },
  ];

  const getDuration = (university: any) => {
    const startDate = moment(university.startDate).format('DD.MM.YYYY');
    const endDate = moment(university.endDate).format('DD.MM.YYYY');

    return `${startDate} - ${endDate}`;
  };

  const deleteUniversityClickHandler = (universityId: string) => {
    updateUserProfile({
      educationalBackground: [
        {
          _id: universityId,
          delete: true,
        },
      ],
    });
  };

  return isAddingUniversity ? (
    <UniversitySectionForm
      className="mt-6"
      isModeAddNew
      onCloseEditForm={() => setIsAddingUniversity(false)}
    />
  ) : (
    <SessionModalSection
      className="mt-6"
      sectionTitle="University (attended)"
      buttonsArray={universityButtons}
    >
      {universities?.length ? (
        <Table size="small">
          <Table.Header>
            <Table.Row isHeader>
              <Table.HeaderCell className="w-1/4">University</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Duration</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Major</Table.HeaderCell>
              <Table.HeaderCell className="w-1/4">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {universities.map((item) => (
              <Table.Row key={item._id}>
                {universityToEdit?._id === item._id ? (
                  <Table.Cell className="!p-0" colSpan={4}>
                    <UniversitySectionForm
                      className="rounded-none"
                      universityData={item}
                      onCloseEditForm={() => setUniversityToEdit(undefined)}
                    />
                  </Table.Cell>
                ) : (
                  <>
                    <Table.Cell>{item.educationalInstitution.name}</Table.Cell>
                    <Table.Cell>{getDuration(item)}</Table.Cell>
                    <Table.Cell>{item.major?.name}</Table.Cell>
                    <Table.Cell className="flex gap-4">
                      <IconButton
                        size="small"
                        variant="ghost"
                        icon={<EditIcon />}
                        onClick={() => setUniversityToEdit(item)}
                      />

                      <IconButton
                        size="small"
                        variant="ghost"
                        color="danger"
                        icon={<TrashIcon />}
                        onClick={() => deleteUniversityClickHandler(item._id)}
                      />
                    </Table.Cell>
                  </>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <EmptyStateCard
          text="No Existing Data"
          icon={<NoDataIcon className="text-customGrey" />}
        />
      )}
    </SessionModalSection>
  );
};

export default UniversitySection;
