import { ReactComponent as ChatIcon } from '@assets/icons/video-icons/chat-icon.svg';
import { ReactComponent as MeetingNotesIcon } from '@assets/icons/video-icons/meeting-notes-icon.svg';
import { ReactComponent as ParticipantsIcon } from '@assets/icons/video-icons/participants-icon.svg';
import { ReactComponent as TranscriptIcon } from '@assets/icons/video-icons/transcript-icon.svg';
import IconButton from '@components/V4/IconButton';
import Tooltip from '@components/V4/Tooltip';
import { PageNameType } from '@modules/MeetingVideo/types/PageNameType';

import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useEffect, useState } from 'react';

let videoActionButtons = [
  {
    icon: <ChatIcon className="w-full h-full" />,
    name: 'chat',
    tooltipText: {
      active: 'Hide Chat',
      inactive: 'Chat',
    },
  },
  {
    icon: <MeetingNotesIcon className="w-full h-full" />,
    name: 'notes',
    tooltipText: {
      active: "Hide Mentor's Notes",
      inactive: "Mentor's Notes",
    },
  },
  {
    icon: <ParticipantsIcon className="w-full h-full" />,
    tooltipText: {
      active: 'Hide Participants List',
      inactive: 'Participants List',
    },
    name: 'participants',
  },
  {
    icon: <TranscriptIcon className="w-full h-full" />,
    name: 'transcript',
    tooltipText: {
      active: 'Hide Transcript',
      inactive: 'Transcript',
    },
  },
];

type Props = {
  className?: string;
  activeButton?: PageNameType;
  isMentor: boolean;
  unreadCount: number;
  handleShowSidebar: (pageName: PageNameType) => void;
  setActiveButton: (pageName: PageNameType) => void;
  handleCloseSidebar: () => void;
};

const MeetingActionButtons = (props: Props) => {
  const {
    className,
    activeButton,
    isMentor,
    unreadCount,
    handleShowSidebar,
    setActiveButton,
    handleCloseSidebar,
  } = props;

  const [readCount, setReadCount] = useState<number>(0);

  useEffect(() => {
    if (activeButton === 'chat') {
      setReadCount((prev) => (prev = unreadCount));
    }
  }, [unreadCount, activeButton]);

  const handleClick = (buttonName: PageNameType) => {
    if (activeButton === buttonName) {
      handleCloseSidebar();
    } else {
      setActiveButton(buttonName);
      handleShowSidebar(buttonName);
    }
  };

  if (!isMentor) {
    videoActionButtons = [
      {
        icon: <ChatIcon className="w-full h-full" />,
        name: 'chat',
        tooltipText: {
          active: 'Hide Chat',
          inactive: 'Chat',
        },
      },
      {
        icon: <MeetingNotesIcon className="w-full h-full" />,
        name: 'notes',
        tooltipText: {
          active: "Hide Mentor's Notes",
          inactive: "Mentor's Notes",
        },
      },
      {
        icon: <ParticipantsIcon className="w-full h-full" />,
        tooltipText: {
          active: 'Hide Participants List',
          inactive: 'Participants List',
        },
        name: 'participants',
      },
    ];
  }

  return (
    <div className={mergeClassNames('gap-6 flex laptop:block', className)}>
      {videoActionButtons.map((button, index) => (
        <Tooltip
          text={
            activeButton === button.name
              ? button.tooltipText.active
              : button.tooltipText.inactive
          }
          placement="top"
          className="relative"
        >
          <IconButton
            key={index}
            className="relative w-8 h-8 tablet:w-10 tablet:h-10"
            icon={button.icon}
            isActive={button.name === activeButton}
            variant="secondary"
            size="large"
            onClick={() => handleClick(button.name as PageNameType)}
          />

          {button.name === 'chat' &&
            activeButton !== 'chat' &&
            !!unreadCount && (
              <p className="absolute top-0 right-0 bg-customRed rounded-full text-xxs w-5 h-5 text-center p-[3px]">
                {unreadCount}
              </p>
            )}
        </Tooltip>
      ))}
    </div>
  );
};

export default MeetingActionButtons;
