import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';
import { ReactComponent as LocationIcon } from '@assets/icons/profile-icons/location-icon.svg';
import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import Button from '@components/V4/Button';
import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { countryList, supportedLanguages, timezones } from '@shared/constants';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import React, { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import StudentAsideInfo from './StudentAsideInfo';

interface StudentAsideLocationProps {
  location: string;
  city: string;
  country: string;
  timezone: string;
  language: string;
  userId: string;
  hasEditPermissions: boolean;
}

const additionalInfoSchema = z.object({
  city: z.string().min(1, { message: 'Please enter a valid city.' }),
  country: z.string().min(1, { message: 'Please enter a valid country.' }),
  timezone: z.string().min(1, { message: 'Please enter a valid timezone.' }),
  preferredLanguage: z
    .string()
    .min(1, { message: 'Please enter a valid language.' }),
});

type FormData = z.infer<typeof additionalInfoSchema>;

const StudentAsideLocation = (props: StudentAsideLocationProps) => {
  const {
    location,
    timezone,
    language,
    userId,
    city,
    country,
    hasEditPermissions,
  } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [showToast] = useToast();
  const query = useQueryClient();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(additionalInfoSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      country,
      city,
      timezone,
      preferredLanguage: language,
    },
  });

  const { field: countryField, fieldState: countryFieldState } = useController({
    name: 'country',
    control,
  });

  const { field: timezoneField, fieldState: timezoneFieldState } =
    useController({
      name: 'timezone',
      control,
    });

  const { field: languageField, fieldState: languageFieldState } =
    useController({
      name: 'preferredLanguage',
      control,
    });

  const editButtonHandler = () => {
    setIsEditMode((prev) => !prev);
    reset();
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUserProfile({
        timezone: data.timezone,
        preferredLanguage: data.preferredLanguage,
        city: data.city,
        country: data.country,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (isEditMode) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, [isEditMode]);

  return isEditMode ? (
    <div className="flex gap-6 py-4 bg-surfaceHover px-6 rounded-xl">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-4 w-full">
          <LocationIcon />

          <h3 className="font-raleway font-bold">Location & Language</h3>
        </div>
        <div className="flex flex-col gap-6">
          <h4 className=" text-customPrimary font-raleway text-xsm font-bold">
            Edit Location & Language
          </h4>
          <Input
            type="text"
            autofocus
            id="city"
            placeholder="E.g. Amsterdam"
            label="City"
            isRequired
            isValid={!errors.city?.message}
            isTouched={touchedFields.city}
            errorText={errors.city?.message}
            {...register('city')}
          />
          <AgoraBigSelector
            value={countryField.value}
            onSelect={(value) => countryField.onChange(value)}
            onClear={() => countryField.onChange('')}
            onBlur={countryField.onBlur}
            options={countryList.map((country) => ({
              label: country,
              value: country,
            }))}
            label="Country"
            labelKey="label"
            valueKey="value"
            isRequired
            isValid={!countryFieldState.error}
            isTouched={countryFieldState.isTouched}
            errorText={countryFieldState.error?.message}
          />

          <AgoraBigSelector
            value={timezoneField.value}
            onSelect={(value) => timezoneField.onChange(value)}
            onClear={() => timezoneField.onChange('')}
            onBlur={timezoneField.onBlur}
            options={timezones.map((timezone) => ({
              label: timezone,
              value: timezone,
            }))}
            label="Timezone"
            labelKey="label"
            valueKey="value"
            isRequired
            isValid={!timezoneFieldState.error}
            isTouched={timezoneFieldState.isTouched}
            errorText={timezoneFieldState.error?.message}
          />

          <AgoraBigSelector
            value={languageField.value}
            onSelect={(value) => languageField.onChange(value)}
            onClear={() => languageField.onChange('')}
            onBlur={languageField.onBlur}
            options={supportedLanguages.map((language) => ({
              label: language,
              value: language,
            }))}
            label="Preffered Language"
            labelKey="label"
            valueKey="value"
            isRequired
            isValid={!languageFieldState.error}
            isTouched={languageFieldState.isTouched}
            errorText={languageFieldState.error?.message}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button
            buttonText="Cancel"
            variant="secondary"
            onMouseDown={editButtonHandler}
          />
          <Button
            buttonText="Save"
            variant="primary"
            isLoading={isLoading}
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex gap-6 py-4 px-6">
      <div className="flex gap-4 w-full">
        <LocationIcon />
        <div className="flex flex-col gap-6">
          <h3 className="font-raleway font-bold">Location & Language</h3>
          <StudentAsideInfo label="Location" value={location} />
          <StudentAsideInfo label="Timezone" value={timezone} />
          <StudentAsideInfo label="Preferred Language" value={language} />
        </div>
      </div>

      {hasEditPermissions && (
        <EditIcon
          onClick={editButtonHandler}
          className="min-w-3 min-h-3 cursor-pointer"
        />
      )}
    </div>
  );
};

export default StudentAsideLocation;
