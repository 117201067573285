import { ExclamationCircleFilled } from '@ant-design/icons';
import UploadInvoiceModal from '@components/UploadInvoiceModal/UploadInvoiceModal';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import { useModal } from '@hooks';
import authHeader from '@services/auth-header';
import { Session } from '@shared/common';
import {
  ExtraServiceStatus,
  OldMeetingStatus,
  ReportStatus,
  SESSION_STATUSES,
  SessionStatus,
} from '@shared/constants';
import { BASE_URL } from '@shared/frontendEnv';
import { useMarkSessionAsCompleted } from '@shared/react';
import rowStyles from '@styles/components/activity-report-row.module.scss';
import styles from '@styles/components/table.module.scss';
import { Checkbox, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';

interface Props {
  selectedDate: any;
  report: any;
  completedNumber?: number;
  isEven: boolean;
  handleSelected: (report: any) => void;
  selectAll: boolean;
  selectedItems: any[];
  handleRowTouched: () => void;
  setSelectedReportId: (id: string) => void;
  setShowSideBySideModal: (show: boolean) => void;
  setShowElectronicInvoiceModal: (show: boolean) => void;
}

const ActivityReportRow: React.FC<Props> = ({
  selectedDate,
  report,
  isEven,
  handleSelected,
  selectAll,
  selectedItems,
  handleRowTouched,
  setSelectedReportId,
  setShowSideBySideModal,
  setShowElectronicInvoiceModal,
}) => {
  const [selectedRow, setSelectedRow] = useState(false);
  const [invoiceUploaded, setInvoiceUploaded] = useState(!!report.invoiceId);
  const [showSecondRow, setShowSecondRow] = useState(false);
  const [showDownloadActivityReport, setShowDownloadActivityReport] =
    useState<boolean>(!!report.activityReportId);

  const [showElectronicInvoice] = useState(
    report.status === ReportStatus.INVOICE_ACCEPTED && invoiceUploaded
  );

  const [showSideBySide] = useState(
    report.status >= ReportStatus.INVOICE_UPLOADED &&
      report.status !== ReportStatus.PAID &&
      invoiceUploaded &&
      showDownloadActivityReport &&
      !showElectronicInvoice
  );

  const [showUploadInvoice, setShowUploadInvoice] = useState(
    report.status >= ReportStatus.AWAIT_INVOICE &&
      report.status !== ReportStatus.PAID
  );
  const [showRegenerateReport, setShowRegenerateReport] = useState(
    report.status !== ReportStatus.PAID &&
      moment(selectedDate).isSame(moment(), 'month')
  );

  const [isLoadingRegenerateReport, setIsLoadingRegenerateReport] =
    useState(false);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const getStatus = (
    sessionStatus: SessionStatus,
    meetingStatus: OldMeetingStatus
  ) => {
    if (sessionStatus === undefined) {
      if (
        meetingStatus === OldMeetingStatus.COMPLETED ||
        meetingStatus === OldMeetingStatus.COMPLETED_ADMIN
      ) {
        return 'Completed';
      } else if (meetingStatus === OldMeetingStatus.PAID) {
        return 'Paid';
      } else {
        return 'Uncompleted';
      }
    }
    return SESSION_STATUSES[sessionStatus];
  };

  const handleSetInvoiceUploaded = (value: any) => {
    setInvoiceUploaded(value);
  };

  const [Modal, show] = useModal(UploadInvoiceModal, {
    reportId: report._id,
    setInvoiceUploaded: handleSetInvoiceUploaded,
    handleRowTouched: handleRowTouched,
  });

  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  const invoiceStatus = ReportStatus[report.status].replaceAll('_', ' ');

  const saveByteArrayFile = (
    blob: any,
    report: any,
    isActivityReport: boolean
  ) => {
    const url = window.URL.createObjectURL(blob.data);
    const link = document.createElement('a');
    link.href = url;

    const monthName = moment(selectedDate)
      .locale('ro')
      .format('MMMM')
      .toLocaleUpperCase();

    const fileName = `${
      isActivityReport ? 'Activity Report' : 'Factura mentor'
    } ${monthName} ${report?.mentor?.fullName}.pdf`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadInvoice = async (report: any) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/report/${report._id}/download-invoice`,
        { headers: authHeader(), responseType: 'blob' }
      );
      message.success('Invoice downloaded successfully');
      saveByteArrayFile(response, report, false);
    } catch (error: any) {
      console.log('error: ', error?.response?.status);
      if (error?.response?.status === 404) {
        message.error('Report was not found');
      }
    }
  };

  const handleDownloadActivityReport = async (report: any) => {
    setIsLoadingDownload(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/report/${report._id}/download-activity-report`,
        { headers: authHeader(), responseType: 'blob' }
      );
      message.success('Activity Report downloaded successfully');
      saveByteArrayFile(response, report, true);
    } catch (error: any) {
      console.log('error: ', error?.response?.status);
      if (error?.response?.status === 404) {
        message.error('Report was not found');
      }
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const handleRegenerateReport = async (report: any) => {
    setIsLoadingRegenerateReport(true);
    try {
      const newReport = await axios.post(
        `${BASE_URL}/api/report/${report._id}/regenerate`,
        {},
        { headers: authHeader() }
      );

      message.success('Report regenerated successfully');
      if (newReport.data.activityReportId) {
        setShowDownloadActivityReport(true);
      }
    } catch (error: any) {
      message.error(error.response?.data?.error?.message);
    } finally {
      setIsLoadingRegenerateReport(false);
    }
    handleRowTouched();
  };

  const handleSelect = () => {
    handleSelected(report);
    setSelectedRow(!selectedRow);
  };

  const { mutate: markSessionAsCompleted } = useMarkSessionAsCompleted({
    onSuccess: () => {
      message.success('Session updated successfully');
      handleRowTouched();
    },
  });

  const [showEditSessionModal, setShowEditSessionModal] = useState(false);
  const [session, setSession] = useState<Session>();

  const editClickHandler = (record: Session) => {
    setShowEditSessionModal(true);
    setSession(record);
  };

  return (
    <>
      {/*FIRST ROW*/}
      <div
        className={`${rowStyles.clickableItem} ${classes} ${styles.gridTableRowFirst} `}
        onClick={() => {
          setShowSecondRow(!showSecondRow);
        }}
      >
        <Checkbox
          checked={selectedItems.includes(report)}
          onClick={(event) => {
            event.stopPropagation();
            handleSelect();
          }}
        />
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.mentor?.fullName}
        {report?.sessions?.some(
          (session: any) =>
            session?.statuses?.slice().reverse()?.[0]?.value ===
            SessionStatus.PENDING_REVIEW
        ) && (
          <ExclamationCircleFilled className={rowStyles.exclamationCircle} />
        )}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {invoiceStatus}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.noOfCompletedSessions}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.noOfActiveStudents}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.hubspotCurrency} {report?.paymentAmountInHC?.toFixed(2)}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.invoiceCurrency}{' '}
        {report?.paymentAmountInExtraService?.toFixed(2)}
      </div>
      <div
        onClick={() => setShowSecondRow(!showSecondRow)}
        className={`${rowStyles.clickableItem} ${classes}`}
      >
        {report?.invoiceCurrency} {report?.paymentAmountInIC?.toFixed(2)}
      </div>
      <div
        style={{
          justifyContent: 'center',
          paddingTop: '8px',
          paddingBottom: '8px',
          minHeight: '120px',
        }}
        className={`${rowStyles.optionsContainer} ${classes} ${styles.gridTableRowLast}`}
        onClick={() => setShowSecondRow(!showSecondRow)}
      >
        {showDownloadActivityReport && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              handleDownloadActivityReport(report);
            }}
            className={rowStyles.clickableItem}
          >
            {isLoadingDownload ? (
              <AgoraSpinner useBlack={true} fontSize={22} margin="0" />
            ) : (
              'Download Report'
            )}
          </span>
        )}

        {showRegenerateReport && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              handleRegenerateReport(report);
            }}
            style={{
              cursor: isLoadingRegenerateReport ? 'default' : 'pointer',
              color: isLoadingRegenerateReport ? 'gray' : 'inherit',
            }}
          >
            {isLoadingRegenerateReport ? 'Loading...' : 'Regenerate Report'}
          </span>
        )}
        {invoiceUploaded && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              handleDownloadInvoice(report);
            }}
            className={rowStyles.clickableItem}
          >
            Download Invoice
          </span>
        )}
        {Modal}
        {showUploadInvoice && (
          <span
            onClick={(event) => {
              event.stopPropagation();
              show({
                reportId: report._id,
                setInvoiceUploaded: handleSetInvoiceUploaded,
                handleRowTouched,
              });
            }}
            className={rowStyles.clickableItem}
          >
            {report.invoiceId ? 'Override Invoice' : 'Upload Invoice'}
          </span>
        )}
        {showSideBySide ? (
          <span
            onClick={(event) => {
              event.stopPropagation();
              setSelectedReportId(report._id);
              setShowSideBySideModal(true);
              setShowElectronicInvoiceModal(false);
            }}
            className={rowStyles.clickableItem}
          >
            View Side-by-Side
          </span>
        ) : showElectronicInvoice ? (
          <span
            onClick={(event) => {
              event.stopPropagation();
              setSelectedReportId(report._id);
              setShowElectronicInvoiceModal(true);
              setShowSideBySideModal(false);
            }}
            className={rowStyles.clickableItem}
          >
            Verify E-Invoice
          </span>
        ) : null}
      </div>
      {/*SECOND EXTRA ROW*/}
      <div style={{ gridColumn: '2/-1', minHeight: '10px' }}>
        {showSecondRow && (
          <div style={{ margin: '20px 0' }}>
            {/*HEADER*/}
            <h1 style={{ marginTop: '32px' }}>Sessions</h1>

            <div className={rowStyles.headerContainer}>
              <span style={{ width: '25%' }}>Session</span>
              <span style={{ width: '25%' }}>Date</span>
              <span style={{ width: '20%' }}>Student</span>
              <span style={{ width: '10%' }}>Payment</span>
              <span style={{ width: '10%' }}>Status</span>
              <span style={{ width: '10%' }}>Actions</span>
            </div>

            {/*DATA ROWS*/}
            {/* Sessions can be of type Meetings (if report if old, which means before 1 nov 2024), or of type Sessions, which is the new report type */}
            {report?.sessions?.map((entity: any, index: number) => {
              const sessionStatus = entity?.statuses?.slice().reverse()?.[0];
              const isSessionCompletedOrPaid =
                [SessionStatus.COMPLETED, SessionStatus.PAID].includes(
                  sessionStatus
                ) ||
                [
                  OldMeetingStatus.COMPLETED,
                  OldMeetingStatus.COMPLETED_ADMIN,
                  OldMeetingStatus.PAID,
                ].includes(entity?.status);
              return (
                entity !== null && (
                  <div className={rowStyles.rowContainer}>
                    <span style={{ width: '25%' }}>
                      {sessionStatus === SessionStatus.PENDING_REVIEW && (
                        <ExclamationCircleFilled
                          className={rowStyles.exclamationCircleFilled}
                        />
                      )}
                      #{index + 1}. {entity?.name}
                    </span>
                    <span style={{ width: '25%' }}>
                      {moment(entity.startDate).format('DD MMM YYYY, HH:MM')}
                    </span>
                    <span
                      style={{
                        width: '20%',
                        cursor: 'pointer',
                        color: 'rgb(51, 169, 171)',
                      }}
                      onClick={() => editClickHandler(entity)}
                    >
                      {entity?.students[0]?.fullName}
                    </span>
                    <span
                      style={{
                        width: '10%',
                        color: isSessionCompletedOrPaid
                          ? 'inherit'
                          : 'rgb(207, 19, 34)',
                      }}
                    >
                      {isSessionCompletedOrPaid ? (
                        <>
                          {report?.rate?.amount ?? '0'}{' '}
                          {report?.hubspotCurrency}
                        </>
                      ) : (
                        <>0 {report?.hubspotCurrency}</>
                      )}
                    </span>
                    <span style={{ width: '10%' }}>
                      {getStatus(sessionStatus, entity?.status)}
                    </span>
                    <span style={{ width: '10%', color: 'rgb(88, 88, 88)' }}>
                      {sessionStatus === SessionStatus.PENDING_REVIEW && (
                        <span
                          onClick={() => {
                            markSessionAsCompleted({
                              sessionId: entity?._id,
                            });
                          }}
                          className={rowStyles.markAsCompleted}
                        >
                          Mark as completed
                        </span>
                      )}
                    </span>
                  </div>
                )
              );
            })}

            {/*EXTRA SERVICES ROW*/}
            <h1 style={{ marginTop: '32px' }}>Extra Services</h1>

            <div className={rowStyles.headerContainer}>
              <span style={{ width: '20%' }}>Mentor</span>
              <span style={{ width: '20%' }}>Extra Activity</span>
              <span style={{ width: '20%' }}>Description</span>
              <span style={{ width: '20%' }}>Amount</span>
              <span style={{ width: '20%' }}>Status</span>
            </div>
            {report?.extraServices?.map(
              (extraService: any | number | string, index: number) => {
                return (
                  session !== null && (
                    <div className={rowStyles.rowContainer}>
                      <span style={{ width: '20%' }}>
                        {extraService?.mentor?.fullName}
                      </span>
                      <span style={{ width: '20%' }}>
                        {extraService?.serviceType.charAt(0).toUpperCase() +
                          extraService?.serviceType.slice(1)}
                      </span>
                      <span style={{ width: '20%' }}>
                        {extraService?.description}
                      </span>
                      <span
                        style={{
                          width: '20%',
                        }}
                      >
                        {extraService?.amount} {extraService?.currency}
                      </span>
                      <span style={{ width: '20%' }}>
                        {ExtraServiceStatus[extraService?.status]}
                      </span>
                    </div>
                  )
                );
              }
            )}
          </div>
        )}
      </div>
      {/* {
      //todo: add session edit modal
        showEditSessionModal && (
        <EditMeetingModal
          meeting={meeting!}
          isModalOpen={showEditMeetingModal}
          setModalOpen={setShowEditMeetingModal}
        />
      )} */}
    </>
  );
};

export default ActivityReportRow;
