import { ReactComponent as ArrowIcon } from '@assets/icons/V4/arrow-icon.svg';
import { ReactComponent as OptionsIcon } from '@assets/icons/V4/options-icon.svg';
import Button from '@components/V4/Button';
import IconButton from '@components/V4/IconButton';
import IconButtonDropdown from '@components/V4/IconButtonDropdown';
import moment from 'moment';
import { ApplicationStatuses } from '../../../../types';
import UniversitiesSectionForm from '../UniversitiesSectionForm';
import ApplicationStatusPill from './ApplicationStatusPill';
import { CardProps } from './UniversitiesSectionCard';

const UniversitiesSectionCardMobile = (props: CardProps) => {
  const {
    dropdownItems,
    arrLength,
    userId,
    universityGoal,
    isShowMore,
    isEditMode,
    setIsShowMore,
    setIsEditMode,
    onCancelEdit,
    incrementOrderHandler,
    decrementOrderHandler,
  } = props;

  return isEditMode ? (
    <div className="flex flex-col gap-3 bg-surfaceHover rounded-t-lg pt-4 tablet:hidden">
      <div className="flex flex-col gap-4 px-4 tablet:hidden">
        <p className="font-raleway font-bold text-xl text-customPrimary">
          #{universityGoal.order}
        </p>
        <h4 className="text-base font-bold font-raleway">
          {universityGoal.university.name}
        </h4>
      </div>
      <UniversitiesSectionForm
        goalData={universityGoal}
        userId={userId}
        isModeAddNew={false}
        onCancel={onCancelEdit}
        setIsEditMode={setIsEditMode}
        className="px-0 tablet:hidden"
        contentClassName="bg-opacity-50 pt-0"
      />
    </div>
  ) : (
    <div className="group relative flex flex-col justify-between gap-4 px-4 py-6 bg-surfaceHover rounded-lg tablet:hidden">
      <div className="flex justify-between tablet:gap-6">
        <h3 className="text-customPrimary text-xl font-bold font-raleway">
          #{universityGoal.order}
        </h3>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex justify-end gap-4 w-full tablet:justify-between">
            {isEditMode ? (
              <UniversitiesSectionForm
                goalData={universityGoal}
                userId={userId}
                isModeAddNew={false}
                onCancel={onCancelEdit}
                setIsEditMode={setIsEditMode}
                className="px-0 tablet:hidden"
                contentClassName="bg-opacity-50 pt-0"
              />
            ) : (
              <div className="flex items-center gap-3 tablet:h-fit laptop:hidden laptop:group-hover:flex">
                {universityGoal.order !== 1 && (
                  <IconButton
                    className="flex items-center justify-center"
                    icon={<ArrowIcon />}
                    onClick={decrementOrderHandler}
                  />
                )}
                {arrLength && universityGoal.order !== arrLength && (
                  <IconButton
                    className="flex items-center justify-center"
                    icon={<ArrowIcon className="rotate-180 " />}
                    onClick={incrementOrderHandler}
                  />
                )}
                <IconButtonDropdown
                  className="flex"
                  icon={<OptionsIcon className="min-w-6" />}
                  items={dropdownItems}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {!isEditMode && (
        <div className="flex flex-col gap-3 tablet:hidden">
          <h4 className="text-base font-bold font-raleway">
            {universityGoal.university.name}
          </h4>
          <ApplicationStatusPill
            status={universityGoal.status as ApplicationStatuses}
          />

          <p className="text-sm tablet:hidden">{universityGoal.major.name}</p>

          <div className="flex gap-3">
            <p className="text-sm text-customGrey">Application Deadline:</p>
            <p className="text-sm">
              {universityGoal.applicationDeadline
                ? moment(universityGoal.applicationDeadline).format(
                    'DD MMMM YYYY'
                  )
                : ''}
            </p>
          </div>

          {isShowMore && (
            <>
              <p className="text-sm">
                <span className="text-sm text-customGrey mr-3">Pro's:</span>
                {universityGoal.pros}
              </p>

              <p className="text-sm">
                <span className="text-sm text-customGrey mr-3">Con's:</span>
                {universityGoal.cons}
              </p>

              <p className="text-sm">
                <span className="text-sm text-customGrey mr-3">
                  Academic Requirements:
                </span>
                {universityGoal.academicRequirements}
              </p>
            </>
          )}
        </div>
      )}

      <Button
        variant="text"
        className="text-customPrimary w-fit ml-auto laptop:absolute laptop:bottom-6 laptop:right-8 laptop:hidden laptop:group-hover:flex"
        buttonText={`Show ${isShowMore ? 'Less' : 'More'} Info`}
        onClick={() => setIsShowMore((prev) => !prev)}
      />
    </div>
  );
};

export default UniversitiesSectionCardMobile;
