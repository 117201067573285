import { ReactComponent as CameraIconActive } from '@assets/icons/video-icons/camera-icon-active.svg';
import { ReactComponent as CameraIcon } from '@assets/icons/video-icons/camera-icon.svg';
import DefaultAvatarIcon from '@assets/icons/video-icons/default-avatar.svg';
import { ReactComponent as MicrophoneIconActive } from '@assets/icons/video-icons/microphone-icon-active.svg';
import { ReactComponent as MicrophoneIcon } from '@assets/icons/video-icons/microphone-icon.svg';
import IconButton from '@components/V4/IconButton';
import Tooltip from '@components/V4/Tooltip';
import { forwardRef } from 'react';

interface Props {
  onMicrophoneClick: () => void;
  onCameraClick: () => void;
  isMicrophoneActive: boolean;
  isCameraActive: boolean;
  isMicrophoneDisabled: boolean;
  isCameraDisabled: boolean;
  avatarUrl?: string;
}

const VideoWidget = forwardRef((props: Props, ref: any) => {
  const {
    onMicrophoneClick,
    onCameraClick,
    isMicrophoneActive,
    isCameraActive,
    isMicrophoneDisabled,
    isCameraDisabled,
    avatarUrl,
  } = props;

  return (
    <div className="relative bg-black">
      <video
        className="w-full aspect-video bg-customGrey tablet:rounded-lg laptop:max-h-full"
        muted={true}
        ref={ref}
        playsInline
        autoPlay
      />
      {(isCameraDisabled || !isCameraActive) && (
        <div className="absolute z-50 flex items-center justify-center bg-surfaceObject w-full h-full top-0 left-0 tablet:rounded-lg">
          <img
            className="w-14 h-14 rounded-full"
            src={avatarUrl || DefaultAvatarIcon}
            alt="Person"
          />
        </div>
      )}
      <div className="absolute z-1000 bottom-5 left-1/2 -translate-x-1/2 flex gap-6">
        <Tooltip
          text={isMicrophoneActive ? 'Mute microphone' : 'Unmute microphone'}
          placement="top"
        >
          <IconButton
            variant="secondary"
            size="large"
            icon={
              isMicrophoneActive ? <MicrophoneIcon /> : <MicrophoneIconActive />
            }
            onClick={() => onMicrophoneClick()}
            isActive={!isMicrophoneActive}
            color="danger"
            isDisabled={isMicrophoneDisabled}
          />
        </Tooltip>
        <Tooltip
          text={isCameraActive ? 'Pause camera' : 'Turn on camera'}
          placement="top"
        >
          <IconButton
            variant="secondary"
            size="large"
            icon={
              isCameraActive ? (
                <CameraIcon className="" />
              ) : (
                <CameraIconActive />
              )
            }
            onClick={() => onCameraClick()}
            isActive={!isCameraActive}
            color="danger"
            isDisabled={isCameraDisabled}
          />
        </Tooltip>
      </div>
    </div>
  );
});

export default VideoWidget;
