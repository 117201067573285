import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import NextMeetingMentor from '@components/V3/NextMeetingsMentor/NextMeetingMentor';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import { useOngoingMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Typography } from 'antd';
import { COLORS } from 'apps/agora/src/v3/global';
import NextMeetingStudent from '../Student/NextMeetingsStudent/NextMeetingStudent';

interface OngoingMeetingsSectionProps {
  isMentor: boolean;
}

const OngoingMeetingsSection = (props: OngoingMeetingsSectionProps) => {
  const { isMentor } = props;
  const { data, isLoading, isError } = useOngoingMeetings();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data?.length) {
    return null;
  }

  return (
    <>
      <div className="mb-8">
        <SectionTitle title="Ongoing Meetings" />
      </div>
      <div className={style.meetings}>
        {isMentor && data?.length
          ? data.map((meeting) => (
              <NextMeetingMentor key={meeting._id} meeting={meeting} />
            ))
          : data.map((meeting) => (
              <NextMeetingStudent key={meeting._id} meeting={meeting} />
            ))}

        {isError && (
          <Typography.Paragraph style={{ color: COLORS.TEXT_PRIMARY }}>
            An error has occurred...
          </Typography.Paragraph>
        )}
      </div>
    </>
  );
};

export default OngoingMeetingsSection;
