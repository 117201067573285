import NextMeeting from '@components/V3/NextMeeting';
import { useModal } from '@hooks';
import StudentDetailsModal from '@modules/Mentor/MyStudents/StudentDetailsModal';
import { MeetingWithHomework } from '@shared/common';
import React from 'react';

interface Props {
  meeting: MeetingWithHomework;
  refetch?: () => void;
  isMentor?: boolean;
}

const MeetingNeedingFeedbackMentor: React.FC<Props> = (props: Props) => {
  const { meeting, isMentor } = props;
  const { students } = meeting;

  const name = students[0]?.fullName || '';

  const [DetailsModal, showDetailsModal] = useModal(StudentDetailsModal);

  return (
    <>
      <NextMeeting
        meetingNeedingFeedback={true}
        meeting={meeting}
        otherName={name}
        isMentor={isMentor}
        showDetailsModal={showDetailsModal}
        hideJoinBtn
      />
      {DetailsModal}
    </>
  );
};

export default MeetingNeedingFeedbackMentor;
