import React from 'react';

interface MeetingDetailProps {
  name: string;
  value: string;
}

const MeetingDetail = (props: MeetingDetailProps) => {
  const { name, value } = props;
  return (
    <div className="flex gap-2">
      <p className="font-raleway font-medium text-xs text-customGrey">{name}</p>
      <p className="font-raleway font-medium text-xs">{value}</p>
    </div>
  );
};

export default MeetingDetail;
