import StepBanner from '@modules/Account/Profile/components/ProfileBanner/StepBanner';
import { useSaveUserProfile } from '@shared/react';
import { UserProfileDTO } from '@shared/types/DTOs/UserDTO';
import useToast from 'apps/agora/src/hooks/useToast';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import StudentMainSection from '../../ResumeSection';
import UniversitiesSectionCard from './UniversitiesSectionCard/UniversitiesSectionCard';
import UniversitiesSectionForm from './UniversitiesSectionForm';

interface UniversitiesSectionProps {
  userId: string;
  userProfile: Partial<UserProfileDTO>;
  isMentorViewer: boolean;
}

const UniversitiesSection = (props: UniversitiesSectionProps) => {
  const { userId, userProfile, isMentorViewer } = props;

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'appliedUniversities',
  });

  const [showToast] = useToast();
  const query = useQueryClient();

  const { mutate: updateUserProfile } = useSaveUserProfile(userId, {
    onSuccess: () => {
      query.invalidateQueries('/users/:id/profile');
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Goals order could not be updated.',
      });
    },
  });

  const onModifyGoalOrder = (newOrder?: number, _id?: string) => {
    if (!newOrder || !_id) return;

    updateUserProfile({
      appliedUniversities: [{ newOrder, _id }],
    });
  };

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <StudentMainSection
      title="University List"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isAddNewItem && (
        <UniversitiesSectionForm
          isModeAddNew
          userId={userId}
          onCancel={addNewItemCancelHandler}
          setIsAddNewItem={setIsAddNewItem}
        />
      )}

      {userProfile.appliedUniversities?.map((goal) => (
        <UniversitiesSectionCard
          key={goal._id}
          arrLength={userProfile.appliedUniversities?.length}
          universityGoal={goal}
          userId={userId}
          onDeleteEntry={deleteEntryHandler}
          onModifyGoalOrder={onModifyGoalOrder}
        />
      ))}

      {!isAddNewItem &&
        !userProfile.appliedUniversities?.length &&
        !isMentorViewer && (
          <StepBanner
            stepOption={'missingUniversityGoal'}
            onClick={() => setIsAddNewItem((prev) => !prev)}
          />
        )}
    </StudentMainSection>
  );
};

export default UniversitiesSection;
