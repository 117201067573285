import { ReactComponent as ArrowIcon } from '@assets/icons/V4/arrow-icon.svg';
import NoDataMeetings from '@assets/icons/no-data-meetings.png';
import MeetingNeedingFeedbackMentor from '@components/V3/MeetingsNeedingFeedbackMentor/MeetingNeedingFeedbackMentor';
import NextMeetingMentor from '@components/V3/NextMeetingsMentor/NextMeetingMentor';
import IconButton from '@components/V4/IconButton';
import Modal from '@components/V4/Modal/Modal';
import { SessionStatus } from '@shared/constants';
import { MeetingWithHomework } from '@shared/types';
import moment, { Moment } from 'moment';
import MeetingButton from '../../Common/Buttons/MeetingButton';

type Props = {
  showModal: boolean;
  handleShowModal: (show: boolean) => void;
  date: Moment;
  isOnHomePage?: boolean;
  dayMeetingsModal: MeetingWithHomework[] | undefined;
  day: moment.Moment;
  handleSelectedDay: (day: Moment) => void;
  goToPreviousDay: () => void;
  goToNextDay: () => void;
};

const CalendarMeetingsModal = ({
  showModal,
  handleShowModal,
  date,
  dayMeetingsModal,
  day,
  handleSelectedDay,
  goToPreviousDay,
  goToNextDay,
}: Props) => {
  const isBeforeToday = date < moment();

  const closeModalHandler = () => {
    handleShowModal(false);
    handleSelectedDay(day);
  };

  return (
    <Modal isOpen={showModal} onClose={closeModalHandler} hasFullMaxHeight>
      <Modal.Header title="Day View" className="mb-6 flex-col">
        <div className="flex justify-between items-center">
          {/*NAV*/}
          <div className="flex items-center gap-6">
            <nav className="flex">
              <IconButton
                icon={<ArrowIcon className="rotate-180" />}
                onClick={goToPreviousDay}
              />
              <IconButton icon={<ArrowIcon />} onClick={goToNextDay} />
            </nav>
            <h3 className="text-base font-raleway font-bold">
              {moment(date).format('MMM Do YYYY')}
            </h3>
          </div>
          <div>
            <MeetingButton
              date={moment(date)}
              disabled={isBeforeToday}
              isOnHomePage={true}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="overflow-x-hidden overflow-y-auto h-110">
        <div className="flex flex-col justify-center">
          {/*CONTENT*/}
          {dayMeetingsModal?.length === 0 ? (
            // NO MEETINGS SCHEDULED
            <div className="flex flex-col items-center justify-center h-full">
              <img
                src={NoDataMeetings}
                alt="no-data"
                className="w-16 h-auto mb-6"
              />
              <p className="font-raleway font-bold text-xl text-customGrey text-center">
                Looks like you have no meetings scheduled.
              </p>
            </div>
          ) : (
            // MEETINGS
            <div className="flex flex-col gap-2">
              {dayMeetingsModal?.map((meeting) =>
                meeting.sessionStatus === SessionStatus.NEEDS_FEEDBACK ? (
                  <MeetingNeedingFeedbackMentor
                    key={meeting._id}
                    meeting={meeting}
                    isMentor={true}
                  />
                ) : (
                  <NextMeetingMentor
                    key={meeting._id}
                    meeting={meeting}
                    isMentor={true}
                  />
                )
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CalendarMeetingsModal;
