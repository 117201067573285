import { LiveTranscriptionLanguage } from '@zoom/videosdk';

export const TRANSCRIPTION_LANGUAGES = [
  { label: 'Romanian', value: LiveTranscriptionLanguage.Romanian },
  { label: 'English', value: LiveTranscriptionLanguage.English },
  { label: 'Spanish', value: LiveTranscriptionLanguage.Spanish },
  { label: 'French', value: LiveTranscriptionLanguage.French },
  { label: 'German', value: LiveTranscriptionLanguage.German },
  { label: 'Italian', value: LiveTranscriptionLanguage.Italian },
];
