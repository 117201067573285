import Modal from '@components/V4/Modal/Modal';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import authHeader from '@services/auth-header';
import {
  CloseModalProps,
  Enrollment,
  ReportStatus,
  Session,
} from '@shared/common';

import { isProduction } from '@shared/env';
import { BASE_URL } from '@shared/frontendEnv';
import { useMarkReportAsPaid } from '@shared/react';
import { message, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AgoraSpinner from './V3/Utils/AgoraSpinner';
import Button from './V4/Button';
const { Title } = Typography;

type UserArg = { _id: string; fullName: string };

type Props = CloseModalProps & {
  name?: string;
  users?: UserArg[];
  enrollmentId?: string;
  defaultEnrollment?: Enrollment;
  defaultStudent?: {
    fullName?: string;
    id: string;
  };
  defaultSession?: Session;
  postSuccess?: () => void;
};

type SideBySideModalProps = {
  reportId?: string;
  invoiceNo: string;
  status: number;
  handleRowTouched: () => void;
  nextReport: () => void;
  prevReport: () => void;
  isFirst: boolean;
  isLast: boolean;
  rejectionReason?: string;
};

const SideBySideModal = (props: Props & SideBySideModalProps) => {
  const {
    visible,
    hide,
    reportId,
    invoiceNo,
    status,
    handleRowTouched,
    nextReport,
    prevReport,
    isFirst,
    isLast,
    rejectionReason,
  } = props;
  const [firstDocumentSrc, setFirstDocumentSrc] = useState<any>();
  const [secondDocumentSrc, setSecondDocumentSrc] = useState<any>();

  const [showReasonInput, setShowReasonInput] = useState(false);
  const [reason, setReason] = useState('');

  const [isDisabled, setIsDisabled] = useState(
    !!firstDocumentSrc && !!secondDocumentSrc
  );

  const [isActivityReportDownloading, setIsActivityReportDownloading] =
    useState(true);

  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(true);

  const handleAccept = async () => {
    try {
      await axios.patch(
        `${BASE_URL}/api/reports/report-status-handler`,
        { reportId, status: 'accepted' },
        { headers: authHeader() }
      );
      message.success('Report accepted successfully');
      handleRowTouched();
      if (isLast) {
        hide();
      } else {
        nextReport();
      }
    } catch (error: any) {
      message.error('Report could not be accepted');
    }
  };

  const handleReject = async () => {
    try {
      await axios.patch(
        `${BASE_URL}/api/reports/report-status-handler`,
        { reportId, status: 'rejected', reason },
        { headers: authHeader() }
      );
      message.success('Report rejected successfully');
      setReason('');
      setShowReasonInput(false);
      handleRowTouched();
      if (isLast) {
        hide();
      } else {
        nextReport();
      }
    } catch (error: any) {
      message.error('Report could not be rejected');
    }
  };

  const { mutate: markReportAsPaid } = useMarkReportAsPaid(reportId as string, {
    onSuccess: () => {
      message.success("Report is now marked as 'PAID'");
      handleRowTouched();
      if (isLast) {
        hide();
      } else {
        nextReport();
      }
    },
  });

  const saveByteArrayFile = (blob: any, isActivityReport: boolean) => {
    const url = window.URL.createObjectURL(
      new Blob([blob.data], { type: 'application/pdf' })
    );
    if (isActivityReport) setSecondDocumentSrc(url);
    else setFirstDocumentSrc(url);
  };

  const handleDownloadInvoice = async (reportId: string) => {
    setIsInvoiceDownloading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/report/${reportId}/download-invoice`,
        { headers: authHeader(), responseType: 'blob' }
      );
      saveByteArrayFile(response, false);
    } catch (error: any) {
      setFirstDocumentSrc(undefined);
      if (isProduction) {
        message.error(`Error: ${(error?.response.message, error?.message)}`);
      }
    } finally {
      setIsInvoiceDownloading(false);
    }
  };

  const handleDownloadActivityReport = async (reportId: string) => {
    setIsActivityReportDownloading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/report/${reportId}/download-activity-report`,
        { headers: authHeader(), responseType: 'blob' }
      );
      saveByteArrayFile(response, true);
    } catch (error: any) {
      setSecondDocumentSrc(undefined);
      if (isProduction) {
        message.error(`Error: ${(error?.response.message, error?.message)}`);
      }
    } finally {
      setIsActivityReportDownloading(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      handleDownloadInvoice(reportId);
      handleDownloadActivityReport(reportId);
    }
  }, [reportId]);

  useEffect(() => {
    setIsDisabled(!firstDocumentSrc || !secondDocumentSrc);
  }, [firstDocumentSrc, secondDocumentSrc]);

  const modalLeftContent = () => {
    if (isInvoiceDownloading)
      return (
        <span className="flex float-left h-full">
          <AgoraSpinner fontSize={100} useBlack={false} />
        </span>
      );

    if (!firstDocumentSrc) {
      return (
        <div className="w-1/2 text-white h-full">
          <Title level={2}>No invoice to display</Title>
        </div>
      );
    }

    return (
      <embed
        src={firstDocumentSrc}
        className="w-1/2 h-full"
        type="application/pdf"
      />
    );
  };

  const modalRightContent = () => {
    if (isActivityReportDownloading) {
      return (
        <span className="flex float-right ml-auto h-full">
          <AgoraSpinner fontSize={100} useBlack={false} />
        </span>
      );
    }

    if (!secondDocumentSrc) {
      return (
        <div className="w-1/2 text-white h-full">
          <Title level={2}>No Activity Report to display</Title>
        </div>
      );
    }

    return (
      <embed
        src={secondDocumentSrc}
        className="w-1/2 h-full ml-4"
        type="application/pdf"
      />
    );
  };
  const modalActionButtons = () => {
    if (rejectionReason && status === ReportStatus.REJECTED) {
      return (
        <span className="px-12 text-error">
          Rejected for: {rejectionReason}
        </span>
      );
    }

    if (!showReasonInput) {
      return (
        <>
          <Button
            buttonText="Reject Invoice"
            size="large"
            className="bg-customRed text-surfaceObject hover:bg-customRedAccent hover:text-surfaceObject"
            onClick={() => {
              setShowReasonInput(true);
            }}
            disabled={
              isDisabled || isInvoiceDownloading || isActivityReportDownloading
            }
          ></Button>
          {status === ReportStatus.READY_FOR_PAYMENT ? (
            <Button
              buttonText="Mark as Paid"
              className="ml-4"
              size="large"
              onClick={() => {
                markReportAsPaid({});
              }}
              disabled={
                isDisabled ||
                isInvoiceDownloading ||
                isActivityReportDownloading
              }
            ></Button>
          ) : (
            <Button
              buttonText="Accept Invoice"
              size="large"
              className="ml-4"
              onClick={() => {
                handleAccept();
              }}
              disabled={
                isDisabled ||
                isInvoiceDownloading ||
                isActivityReportDownloading
              }
            ></Button>
          )}
        </>
      );
    }

    return (
      <div className="flex items-center">
        <TextArea
          placeholder="Reason for rejection..."
          onChange={(e) => setReason(e.target.value)}
        />
        <Button
          buttonText="Submit"
          variant="secondary"
          className="mr-4 ml-4"
          onClick={handleReject}
        ></Button>
        <Button
          buttonText="Cancel"
          variant="secondary"
          onClick={() => setShowReasonInput(false)}
        ></Button>
      </div>
    );
  };

  return (
    <Modal isOpen={visible} onClose={hide} size="fullScreen" hasFullMaxHeight>
      <Modal.Header
        title={`Compare Invoice & Activity Report`}
        className="mb-6"
      />
      <Modal.Body>
        <div className="flex w-full h-full">
          {modalLeftContent()}
          {modalRightContent()}
        </div>
        <div className="flex justify-start text-white mt-4">
          Invoice Number: {invoiceNo}
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-4">
        <div className="flex justify-between">
          <Button
            buttonText="Previous"
            variant="secondary"
            disabled={isFirst}
            onClick={() => {
              setReason('');
              setShowReasonInput(false);
              prevReport();
            }}
            icon={<ChevronLeftIcon width={18} />}
          ></Button>

          <div className="flex">{modalActionButtons()}</div>

          <Button
            buttonText="Next"
            variant="secondary"
            className="flex-row-reverse"
            disabled={isLast}
            onClick={() => {
              setReason('');
              setShowReasonInput(false);
              nextReport();
            }}
            icon={<ChevronRightIcon width={18} />}
          ></Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SideBySideModal;
