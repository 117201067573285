import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { employmentTypes } from '@modules/Account/Profile';
import SessionModalFormWrapper from '@modules/Admin/components/SessionModal/SessionModalFormWrapper';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import { StudentDetailsPageContext } from '../../../StudentDetailsPage';

interface WorkExperienceSectionFormProps extends BasicProps {
  isModeAddNew?: boolean;
  workExperienceData?: any;
  onCloseEditForm: () => void;
}

const formDataSchema = z.object({
  positionTitle: z
    .string()
    .min(1, { message: 'Please enter a valid school name.' }),
  employer: z.string().min(1, { message: 'Please enter a valid name.' }),
  employmentType: z
    .string()
    .min(1, { message: 'Please enter a valid employment type.' }),
  startDate: z.string().min(1, { message: 'Please enter a valid date.' }),
  endDate: z.string().min(1, { message: 'Please enter a valid date.' }),
  description: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const WorkExperienceSectionForm = (props: WorkExperienceSectionFormProps) => {
  const {
    isModeAddNew = false,
    workExperienceData,
    className,
    onCloseEditForm,
  } = props;
  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [showToast] = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(
    studentId,
    {
      onSuccess: async () => {
        showToast({
          variant: 'success',
          messageTitle: 'Success',
          messageBody: 'Successfully updated your profile.',
        });
        onCloseEditForm();
      },
      onError: () => {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: 'Profile could not be saved.',
        });
      },
    }
  );

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      positionTitle: workExperienceData?.positionTitle,
      employer: workExperienceData?.employer,
      employmentType: workExperienceData?.employmentType,
      startDate: workExperienceData?.startDate,
      endDate: workExperienceData?.endDate,
      description: workExperienceData?.description,
    },
  });

  const { field: startDateField, fieldState: startDateFieldState } =
    useController({
      name: 'startDate',
      control,
    });

  const { field: endDateField, fieldState: endDateFieldState } = useController({
    name: 'endDate',
    control,
  });

  const { field: employmentTypeField, fieldState: employmentTypeFieldState } =
    useController({
      name: 'employmentType',
      control,
    });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const workExperience: any = {
        positionTitle: data.positionTitle,
        employer: data.employer,
        employmentType: data.employmentType,
        startDate: data.startDate,
        endDate: data.endDate,
        description: data.description ?? '',
        _id: workExperienceData?._id,
      };

      updateUserProfile({
        workExperiences: [workExperience],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add Work Experience' : 'Edit Work Experience'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
    >
      <Input
        isRequired
        type="text"
        id="positionTitle"
        label="Position Name"
        isTouched={touchedFields.positionTitle}
        isValid={!errors.positionTitle}
        errorText={errors.positionTitle?.message}
        autofocus
        {...register('positionTitle')}
      />
      <div className="flex gap-6">
        <Input
          isRequired
          type="text"
          id="employer"
          label="Employer"
          isTouched={touchedFields.employer}
          isValid={!errors.employer}
          errorText={errors.employer?.message}
          {...register('employer')}
        />

        <Select
          isRequired
          options={employmentTypes?.map((employment) => ({
            label: employment,
            value: employment,
          }))}
          onSelect={(value) => employmentTypeField.onChange(value)}
          value={employmentTypeField.value}
          onBlur={employmentTypeField.onBlur}
          label="Position Type"
          isValid={!employmentTypeFieldState.error}
          isTouched={employmentTypeFieldState.isTouched}
          errorText={employmentTypeFieldState.error?.message}
        />
      </div>
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          isRequired
          value={
            startDateField.value ? moment(startDateField.value) : undefined
          }
          onChange={(value) =>
            startDateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={startDateField.onBlur}
          allowClear={false}
          label="Start Date"
          isValid={!startDateFieldState.error}
          isTouched={startDateFieldState.isTouched}
          errorText={startDateFieldState.error?.message}
        />

        <AgoraDatePicker
          isRequired
          value={endDateField.value ? moment(endDateField.value) : undefined}
          onChange={(value) =>
            endDateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={endDateField.onBlur}
          allowClear={false}
          label="End Date"
          isValid={!endDateFieldState.error}
          isTouched={endDateFieldState.isTouched}
          errorText={endDateFieldState.error?.message}
        />
      </div>
      <TextArea
        id="description"
        label="Description"
        {...register('description')}
      />
    </SessionModalFormWrapper>
  );
};

export default WorkExperienceSectionForm;
