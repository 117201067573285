import Accordion from '@components/V4/Accordion';
import { MEETING_STATUSES, MeetingStatus } from '@shared/common';
import { MeetingWithDuration } from '@shared/types/DTOs/Enrollments.DTO';
import moment from 'moment';
import MeetingDetail from './MeetingDetail';

interface SessionMeetingProps {
  meeting: MeetingWithDuration;
  name: string;
}

interface AttendanceStatus {
  joinTime: string;
  leaveTime: string;
  duration: number;
  isWaitingRoom: boolean;
}

const convertSecondsToTime = (seconds?: number) => {
  if (!seconds) return 'n/a';
  const duration = moment.duration(seconds, 'seconds');
  return moment.utc(duration.asMilliseconds()).format('H[h] mm[m]');
};

const formatAttendance = (attendance: AttendanceStatus) => {
  return attendance.joinTime
    ? `${moment(attendance.joinTime).format('DD MMM, hh:mm')} - ${moment(
        attendance.leaveTime
      ).format('hh:mm')}`
    : 'n/a';
};

const getAttendanceTimes = (
  attendanceStatuses: AttendanceStatus[] | undefined
) => {
  if (!attendanceStatuses?.length) return 'n/a';
  return attendanceStatuses
    .filter((status) => !status.isWaitingRoom)
    .map(formatAttendance)
    .join('; ');
};

const SessionMeeting = ({ meeting, name }: SessionMeetingProps) => {
  const mentorAttendanceTimes = getAttendanceTimes(
    meeting.mentor?.attendance?.status
  );
  const studentAttendanceTimes = getAttendanceTimes(
    meeting.students[0]?.attendance?.status
  );

  return (
    <Accordion
      title={
        <p className="font-raleway font-medium font-xs text-white">
          {name} Details
        </p>
      }
      titleWrapperClassName="bg-surfaceHover p-2"
      className="flex m-2 max-h-[200px] laptop:max-h-[540px]"
    >
      <div className="w-1/2 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <MeetingDetail
            name="Meeting Creation Date:"
            value={
              meeting.createdAt
                ? moment(meeting.createdAt).format('DD.MM.YYYY')
                : 'n/a'
            }
          />
          <MeetingDetail
            name="Meeting Status:"
            value={MEETING_STATUSES[meeting.status]}
          />
        </div>

        <div className="flex flex-col gap-2">
          <MeetingDetail
            name="Meeting Start:"
            value={
              meeting.startTime
                ? moment(meeting.startTime).format('DD MMM, hh:mm')
                : 'n/a'
            }
          />
          <MeetingDetail
            name="Meeting End:"
            value={
              meeting.endTime
                ? moment(meeting.endTime).format('DD MMM, hh:mm')
                : 'n/a'
            }
          />
          <MeetingDetail
            name="Meeting Duration:"
            value={convertSecondsToTime(meeting.duration ?? 0)}
          />
        </div>

        <div className="flex flex-col gap-2">
          <MeetingDetail
            name="Mentor Joined:"
            value={
              mentorAttendanceTimes !== 'n/a'
                ? `${mentorAttendanceTimes} (${convertSecondsToTime(
                    meeting.mentorTotalDuration
                  )})`
                : 'n/a'
            }
          />
          <MeetingDetail
            name="Student Joined:"
            value={
              studentAttendanceTimes !== 'n/a'
                ? `${studentAttendanceTimes} (${convertSecondsToTime(
                    meeting.studentTotalDuration
                  )})`
                : 'n/a'
            }
          />
        </div>
      </div>

      {meeting.status === MeetingStatus.NO_SHOW && (
        <div className="w-1/2">
          <MeetingDetail
            name="NoShow Reason:"
            value={meeting.endMeetingReason ?? 'n/a'}
          />
        </div>
      )}
    </Accordion>
  );
};

export default SessionMeeting;
