import Button from '@components/V4/Button';
import { BasicProps } from 'antd/lib/layout/layout';
import React, { ReactNode } from 'react';

export interface ActionButton {
  buttonText: string;
  onClick: () => void;
}

interface SessionModalSectionProps extends BasicProps {
  sectionTitle: string;
  buttonsArray?: ActionButton[];
}

const SessionModalSection = (props: SessionModalSectionProps) => {
  const { sectionTitle, buttonsArray, children, className } = props;

  return (
    <section className={className}>
      <div className="flex justify-between mb-2">
        <p className="text-xs font-bold h-6">{sectionTitle}</p>

        <div className="flex gap-2">
          {buttonsArray?.map((button, index) => (
            <Button
              key={`${button.buttonText} - ${index}`}
              buttonText={button.buttonText}
              onClick={button.onClick}
            />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};

export default SessionModalSection;
