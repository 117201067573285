import { ReactComponent as ClockIcon } from '@assets/icons/clock-icon.svg';
import { ReactComponent as DropdownIcon } from '@assets/icons/dropdown-icon.svg';

import styles from '@styles/components/reusables/modal.module.scss';
import { AutoComplete } from 'antd';
import moment, { Moment } from 'moment';
import { CSSProperties, FC, useCallback, useEffect } from 'react';
import './custom-form.styles.scss';

type Props = {
  id?: string;
  time?: string;
  handleChange: any;
  handleFocus?: any;
  date?: Moment;
  style?: CSSProperties;
  disablePastHours?: boolean;
  className?: string;
  allowClear?: boolean;
  onBlur?: (e: any) => void;
};

function CustomTimePicker({
  id,
  time,
  handleChange,
  handleFocus,
  date,
  style,
  disablePastHours = true,
  className,
  allowClear = true,
  onBlur,
}: Props) {
  const getTimeArray = useCallback(() => {
    const timeArray = [];
    const isToday = date && moment(date)?.isSame(moment(), 'day');

    for (let i = 0; i <= 23; i++) {
      const hour = `${i}:00`;
      const half = `${i}:30`;

      timeArray.push({
        value: moment(hour, 'H:mm').format('HH:mm'),
        isDisabled: isToday && moment(hour, 'H:mm') < moment(),
      });
      timeArray.push({
        value: moment(half, 'H:mm').format('HH:mm'),
        isDisabled: isToday && moment(half, 'H:mm') < moment(),
      });
    }

    return timeArray;
  }, [date]);

  const resetValueIfInvalid = useCallback(() => {
    const timeArray = getTimeArray();
    const valueIndex = timeArray.findIndex(
      (timeOption) => timeOption.value === time && !timeOption.isDisabled
    );

    const isValueValid = valueIndex > -1;

    if (!isValueValid) {
      handleChange(undefined);
    }
  }, [getTimeArray, time]);

  useEffect(() => {
    resetValueIfInvalid();
  }, [date]);

  const blurHandler = (e: any) => {
    resetValueIfInvalid();
    onBlur?.(e);
  };

  return (
    <AutoComplete
      id={id}
      value={time}
      suffixIcon={<DropdownIcon />}
      notFoundContent={<div style={{ height: '0' }}></div>}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={blurHandler}
      showSearch
      allowClear={allowClear}
      className={`${className} agora-custom-time-picker`}
      style={{
        backgroundColor: 'transparent',
        fontFamily: 'Open Sans, sans-serif',
        position: 'relative',
        width: '100%',
        ...style,
      }}
    >
      {getTimeArray().map((timeOption, index: number) => (
        <AutoComplete.Option
          className={styles.newMeetingModalSelectTime}
          disabled={disablePastHours && timeOption.isDisabled}
          key={index}
          value={timeOption.value}
          style={{ fontFamily: 'Open Sans, sans-serif' }}
        >
          {timeOption.value}
        </AutoComplete.Option>
      ))}
    </AutoComplete>
  );
}

// ! use only outside Form.Item
export const CustomTimePickerIcon: FC<{ timepickerFocus: boolean }> = ({
  timepickerFocus,
}) => (
  <div className={styles.newMeetingModalSelectTimeIcon}>
    {timepickerFocus && <ClockIcon />}
  </div>
);

export default CustomTimePicker;
