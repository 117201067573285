import { ReactComponent as WorkIcon } from '@assets/icons/profile-icons/work-icon.svg';
import { WorkExperience } from '@shared/common';
import moment from 'moment';
import { useState } from 'react';
import ItemContent from '../ItemContent';
import ResumeTabWorkForm from './ResumeTabWorkForm';

interface ResumeTabWorkItemProps {
  userId: string;
  jobDetails: WorkExperience;
  onDeleteEntry: (id?: string) => void;
}

const ResumeTabWorkItem = (props: ResumeTabWorkItemProps) => {
  const { userId, jobDetails, onDeleteEntry } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const cancelEditHandler = () => {
    setIsEditMode(false);
  };

  return isEditMode ? (
    <ResumeTabWorkForm
      jobData={jobDetails}
      isModeAddNew={false}
      onCancel={cancelEditHandler}
      userId={userId}
      setIsEditMode={setIsEditMode}
    />
  ) : (
    <ItemContent
      icon={<WorkIcon className="min-w-5" />}
      onEdit={() => setIsEditMode(true)}
      onDelete={() => onDeleteEntry(jobDetails._id)}
    >
      <ItemContent.Title>
        {jobDetails.positionTitle} <span className="text-customGrey">at</span>{' '}
        {jobDetails.employer}
      </ItemContent.Title>

      <ItemContent.SubTitle>
        {jobDetails.employmentType} <span>•</span>{' '}
        {moment(jobDetails.startDate).format('MMMM YYYY')} -{' '}
        {moment(jobDetails.endDate).format('MMMM YYYY')}
      </ItemContent.SubTitle>

      <ItemContent.Description>
        {jobDetails.description}
      </ItemContent.Description>
    </ItemContent>
  );
};

export default ResumeTabWorkItem;
