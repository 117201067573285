import { DatePicker } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import './agora-date-picker.styles.scss';
import AgoraInputError from '../AgoraInputError';
import calendarIcon from '@assets/icons/input-calendar.svg';
import InputLabel from '@components/V4/Inputs/InputLabel';

type Props = {
  allowClear?: boolean;
  value?: moment.Moment;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  disabledDate?: (e: any) => boolean;
  isRequired?: boolean;
  className?: string;
  isValid?: boolean;
  isTouched?: boolean;
  errorText?: string;
  label?: string;
};

const AgoraDatePicker = ({
  allowClear,
  value,
  onChange,
  onBlur,
  disabledDate,
  isRequired,
  className,
  isValid,
  isTouched,
  errorText,
  label,
}: Props) => {
  return (
    <div
      className={classNames(
        'agora-date-picker',
        className,
        isValid && isTouched
          ? 'agora-date-picker--valid'
          : !isValid && isTouched
          ? 'agora-date-picker--error'
          : ''
      )}
    >
      {label && (
        <InputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
        />
      )}

      <DatePicker
        allowClear={allowClear}
        value={value}
        onChange={onChange}
        disabledDate={disabledDate}
        onBlur={onBlur}
        suffixIcon={
          <img
            className="agora-date-picker-calendar-icon"
            src={calendarIcon}
            alt="Press to expand the date picker."
          />
        }
      />

      {!isValid && isTouched && errorText && (
        <AgoraInputError errorText={errorText} />
      )}
    </div>
  );
};

export default AgoraDatePicker;
