import { ReactComponent as ExpandArrowIcon } from '@assets/icons/video-icons/expand-arrow.svg';
import React, { ReactNode, useState } from 'react';
import { mergeClassNames } from '../../utils/helpers';
import { BasicProps } from '../../utils/types';

interface AccordionProps extends BasicProps {
  title: ReactNode;
  titleWrapperClassName?: string;
}

const Accordion = (props: AccordionProps) => {
  const { title, titleWrapperClassName, className, children } = props;

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <div className="flex flex-col overflow-hidden">
      <div
        className={mergeClassNames(
          'flex items-center justify-between cursor-pointer',
          titleWrapperClassName
        )}
        onClick={() => setIsAccordionOpen((prev) => !prev)}
      >
        {title}
        <ExpandArrowIcon
          className={mergeClassNames(
            'transition-all ease-in-out duration-300',
            {
              'rotate-180': isAccordionOpen,
            }
          )}
        />
      </div>
      <div
        className={mergeClassNames(
          'transition-all ease-in-out duration-300 w-full',
          {
            'max-h-0 my-0 opacity-0': !isAccordionOpen,
            'my-6 opacity-100': isAccordionOpen,
            className: isAccordionOpen,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
