import SessionModalEmptyState from '../../../SessionModalEmptyState';
import ChatEntry from './ChatEntry';

interface SessionChatTabProps {
  chat: any[];
}

const SessionChatTab = (props: SessionChatTabProps) => {
  const { chat } = props;
  return (
    <div className="flex flex-col gap-4">
      {!chat?.length ? (
        <SessionModalEmptyState />
      ) : (
        chat.map((message) => (
          <ChatEntry
            name={message.user}
            timestamp={message.message.at}
            message={message.message.text}
          />
        ))
      )}
    </div>
  );
};

export default SessionChatTab;
