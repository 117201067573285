import { ReactComponent as CompletedIcon } from '@assets/icons/V4/empty-states/completed-meetings-icon.svg';
import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/sad-calendar.svg';
import { Enrollment } from '@shared/common';
import { useStudentEnrollments, useUpcomingMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Spin, Typography } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import EmptyStateCard from '../../../components/EmptyStateCard';
import NextMeetingStudent from './NextMeetingStudent';

const NextMeetingsStudent: React.FC = () => {
  const { data, isLoading, isError } = useUpcomingMeetings();
  //todo: create another api for this
  const { data: enrollments } = useStudentEnrollments();

  const [viewAll, setViewAll] = useState(false);

  if (isLoading) {
    return <Spin />;
  }
  if (isError) {
    return (
      <Typography.Paragraph style={{ color: COLORS.TEXT_PRIMARY }}>
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  if (
    !!enrollments?.length &&
    enrollments?.every((enrollment: Enrollment) => enrollment?.ended === true)
  ) {
    return (
      <EmptyStateCard
        icon={<CompletedIcon />}
        text={`You've completed all your courses and have no more meetings scheduled. 
Congrats on your achievement!`}
      />
    );
  }

  return (
    <div className={style.meetings}>
      {!data?.length ? (
        <EmptyStateCard
          icon={<EmptyStateIcon />}
          text={`No scheduled meetings. 
Talk to your mentor to schedule a meeting & stay on track.`}
        />
      ) : null}

      {!viewAll && data?.length
        ? data.map((meeting, index) =>
            index < 5 ? (
              <NextMeetingStudent key={meeting._id} meeting={meeting} />
            ) : null
          )
        : null}

      {viewAll && data?.length
        ? data.map((meeting, index) => (
            <NextMeetingStudent key={meeting._id} meeting={meeting} />
          ))
        : null}

      {data && data?.length > 5 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              font: 'normal normal medium 13px/16px Urbane Rounded',
              fontSize: '13px',
              color: COLORS.PRIMARY,
              alignSelf: 'flex-end',
            }}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View less' : 'View all'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default NextMeetingsStudent;
