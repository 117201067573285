import VideoMeeting from '@modules/MeetingVideo/components/VideoMeeting/VideoMeeting';
import VideoWaitingRoom from '@modules/MeetingVideo/components/VideoWaitingRoom/VideoWaitingRoom';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { ErrorCodes } from '@shared/constants';
import { useGetMeetingDetails } from '@shared/react';
import ZoomVideo, { LiveTranscriptionLanguage, Stream } from '@zoom/videosdk';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useMeetingWebSocket from './useMeetingWebsocket';

const zoomClient = ZoomVideo.createClient();

const MeetingVideo = () => {
  const [isWaitingRoom, setIsWaitingRoom] = useState(true);
  const params = useLocation().pathname.replace('/video-meeting/', '');
  const [activeCamera, setActiveCamera] = useState<string | undefined>();
  const [activeMicrophone, setActiveMicrophone] = useState<
    string | undefined
  >();
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(false);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isMeetingLoading, setIsMeetingLoading] = useState(false);
  const [canJoinMeeting, setCanJoinMeeting] = useState(false);
  const [transcriptLanguage, setTranscriptLanguage] = useState(
    LiveTranscriptionLanguage.English
  );
  const [stream, setStream] = useState<typeof Stream | undefined>();

  const history = useHistory();

  const { data: meetingDetails } = useGetMeetingDetails(params, {
    onError: (err: any) => {
      if (err.response.data.error.code === ErrorCodes.USER_NOT_AUTHORIZED) {
        history.replace('/home');
      }
    },
  });

  const { webSocket, isSocketConnected } = useMeetingWebSocket();

  const contextValue = {
    stream,
    zoomClient,
    meetingDetails,
    activeCamera,
    activeMicrophone,
    isMicrophoneActive,
    isCameraActive,
    isMeetingLoading,
    canJoinMeeting,
    webSocket,
    isSocketConnected,
    transcriptLanguage,
    setActiveCamera,
    setActiveMicrophone,
    setIsMicrophoneActive,
    setIsCameraActive,
    setIsMeetingLoading,
    setCanJoinMeeting,
    setStream,
    setTranscriptLanguage,
  };

  return (
    <ZoomContext.Provider value={contextValue}>
      {isWaitingRoom ? (
        <VideoWaitingRoom setIsWaitingRoom={setIsWaitingRoom} />
      ) : (
        <VideoMeeting />
      )}
    </ZoomContext.Provider>
  );
};

export default MeetingVideo;
