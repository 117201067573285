import {
  MEETING_STATUSES,
  MeetingStatus,
  SESSION_STATUSES,
  SessionStatus,
} from '@shared/constants';
import { Meeting } from '@shared/types';
import styles from '@styles/components/table.module.scss';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../../../v3/global';
import GiveFeedbackButtonMentor from '../../../../views/UserView/Home/Common/Buttons/GiveFeedbackButtonMentor';

interface Props {
  meeting: Meeting;
  completedNumber?: number;
  isEven: boolean;
}

const SessionHistoryRow: React.FC<Props> = ({ meeting, isEven }) => {
  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  const history = useHistory();

  return (
    <>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowFirst}`}
      >
        {meeting.students?.map((student, index) => (
          <p key={student._id}>
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(`/student-profile/${student._id}`);
              }}
            >
              {student.fullName}
            </span>
            {index + 1 !== meeting.students.length && (
              <span className="mr-1">,</span>
            )}
          </p>
        ))}
        {/* // .join(', ')}} */}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {meeting.enrollment?.title}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(meeting.startDate).format('DD MMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {MEETING_STATUSES[meeting.status]} -{' '}
        {SESSION_STATUSES[meeting.sessionStatus as SessionStatus] ?? 'N/A'}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowLast}`}
      >
        {meeting.status === MeetingStatus.ENDED &&
        meeting.sessionStatus === SessionStatus.NEEDS_FEEDBACK ? (
          <GiveFeedbackButtonMentor meeting={meeting} />
        ) : null}
      </div>
    </>
  );
};

export default SessionHistoryRow;
