import { ReactComponent as WorkIcon } from '@assets/icons/profile-icons/work-icon.svg';
import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { WorkExperience } from '@shared/common';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import moment from 'moment';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { employmentTypes } from '../../../constants';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../StudentFormWrapper';

interface ResumeTabWorkFormProps extends ExamFormProps {
  jobData?: WorkExperience;
}

const formDataSchema = z.object({
  positionTitle: z
    .string()
    .min(1, { message: 'Please enter a valid school name.' }),
  employer: z.string().min(1, { message: 'Please enter a valid name.' }),
  employmentType: z
    .string()
    .min(1, { message: 'Please enter a valid employment type.' }),
  startDate: z.string().min(1, { message: 'Please enter a valid date.' }),
  endDate: z.string().min(1, { message: 'Please enter a valid date.' }),
  description: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const ResumeTabWorkForm = (props: ResumeTabWorkFormProps) => {
  const {
    isModeAddNew,
    userId,
    jobData,
    setIsEditMode,
    setIsAddNewItem,
    onCancel,
  } = props;

  const [showToast] = useToast();
  const query = useQueryClient();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      positionTitle: jobData?.positionTitle,
      employer: jobData?.employer,
      employmentType: jobData?.employmentType,
      startDate: jobData?.startDate,
      endDate: jobData?.endDate,
      description: jobData?.description,
    },
  });

  const { field: startDateField, fieldState: startDateFieldState } =
    useController({
      name: 'startDate',
      control,
    });

  const { field: endDateField, fieldState: endDateFieldState } = useController({
    name: 'endDate',
    control,
  });

  const { field: employmentTypeField, fieldState: employmentTypeFieldState } =
    useController({
      name: 'employmentType',
      control,
    });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const workExperience: WorkExperience = {
        positionTitle: data.positionTitle,
        employer: data.employer,
        employmentType: data.employmentType,
        startDate: data.startDate,
        endDate: data.endDate,
        description: data.description ?? '',
        _id: jobData?._id,
      };

      updateUserProfile({
        workExperiences: [workExperience],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <StudentFormWrapper
      title="Position"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      icon={<WorkIcon className="min-w-5" />}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      <Input
        type="text"
        id={'positionTitle'}
        placeholder={''}
        label={'Position Name'}
        isRequired
        isTouched={touchedFields.positionTitle}
        isValid={!errors.positionTitle}
        errorText={errors.positionTitle?.message}
        autofocus
        {...register('positionTitle')}
      />
      <Input
        type="text"
        id={'employer'}
        placeholder={''}
        label={'Employer'}
        isRequired
        isTouched={touchedFields.employer}
        isValid={!errors.employer}
        errorText={errors.employer?.message}
        {...register('employer')}
      />

      <AgoraBigSelector
        options={employmentTypes?.map((employment) => ({
          label: employment,
          value: employment,
        }))}
        onSelect={(value) => employmentTypeField.onChange(value)}
        value={employmentTypeField.value}
        onBlur={employmentTypeField.onBlur}
        isRequired
        labelKey="label"
        valueKey="value"
        label="Position Type"
        isValid={!employmentTypeFieldState.error}
        isTouched={employmentTypeFieldState.isTouched}
        errorText={employmentTypeFieldState.error?.message}
      />
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={
            startDateField.value ? moment(startDateField.value) : undefined
          }
          onChange={(value) =>
            startDateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={startDateField.onBlur}
          allowClear={false}
          isRequired
          label="Start Date"
          isValid={!startDateFieldState.error}
          isTouched={startDateFieldState.isTouched}
          errorText={startDateFieldState.error?.message}
        />

        <AgoraDatePicker
          value={endDateField.value ? moment(endDateField.value) : undefined}
          onChange={(value) =>
            endDateField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={endDateField.onBlur}
          allowClear={false}
          isRequired
          label="End Date"
          isValid={!endDateFieldState.error}
          isTouched={endDateFieldState.isTouched}
          errorText={endDateFieldState.error?.message}
        />
      </div>
      <TextArea
        id={'description'}
        placeholder={''}
        label={'Description'}
        {...register('description')}
      />
    </StudentFormWrapper>
  );
};

export default ResumeTabWorkForm;
