import CustomTimePicker from '@components/Form/CustomTimePicker';
import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';
import { useMeeting, useRescheduleMeeting } from '@shared/react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import InputLabel from './Inputs/InputLabel';
import useToast from '../../hooks/useToast';

type RescheduleModalProps = {
  isOpen: boolean;
  meetingId: string;
  onClose: () => void;
  onReschedule?: () => void;
};

const RescheduleModal = (props: RescheduleModalProps) => {
  const { isOpen, meetingId, onClose, onReschedule } = props;

  const [date, setDate] = useState(moment());
  const [time, setTime] = useState<string>();

  //TODO - think about a meeting being passed directly instead of fetching it inside this modal
  const { data: meeting } = useMeeting(meetingId);

  const [showToast] = useToast();

  const { mutate: reschedule, isLoading } = useRescheduleMeeting(meetingId, {
    onSuccess() {
      showToast({
        variant: 'info',
        messageBody: 'Meeting successfully rescheduled.',
      });
      onReschedule?.();
    },
    onError(error: any) {
      showToast({
        variant: 'error',
        messageBody:
          error?.response?.data?.error?.message ??
          error?.data?.message ??
          error.message ??
          'An error occurred',
      });
    },
  });

  const rescheduleMeetingHandler = () => {
    const selectedTime = moment(time, 'H:mm');
    const newDateTime =
      date &&
      moment(
        date.set({
          hour: selectedTime.get('hour'),
          minute: selectedTime.get('minute'),
          second: 0,
          millisecond: 0,
        })
      );

    reschedule({ startDate: moment(newDateTime).toISOString() });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header title="Reschedule Meeting" className="text-center" />
      <Modal.Body className="flex flex-col justify-center overflow-x-hidden mt-2 mb-8 gap-8 text-xs leading-5 text-center">
        <p className="w-max">
          Reschedule <b>{meeting?.name}</b> with{' '}
          <b>{meeting?.students[0]?.fullName}</b>
        </p>
        <div className="flex flex-col tablet:flex-row w-full gap-6">
          <div className="flex flex-col w-full">
            <InputLabel label="Date" htmlFor="reschedule-date" isRequired />
            <DatePicker
              id="reschedule-date"
              value={date}
              allowClear={false}
              onChange={(value) => setDate(moment(value))}
              disabledDate={(date) =>
                date < moment(moment().format('YYYY-MM-DD'))
              }
              style={{ width: '100%' }}
            />
          </div>
          <div className="flex flex-col w-full">
            <InputLabel label="Time" htmlFor="reschedule-time" isRequired />
            <CustomTimePicker
              id="reschedule-time"
              allowClear={false}
              time={time}
              handleChange={(value?: string) => setTime(value)}
              date={date}
              disablePastHours={true}
            />
          </div>
        </div>

        <p className="w-max">
          Times are in your local timezone, it is currently{' '}
          {moment().format('HH:mm')}
        </p>
      </Modal.Body>
      <Modal.Footer className="flex justify-center gap-4">
        <Button
          size="large"
          className="w-full"
          variant="primary"
          buttonText="Reschedule"
          isLoading={isLoading}
          onClick={rescheduleMeetingHandler}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default RescheduleModal;
