import { checklistApis } from '@shared/apis';
import { LMSCourse } from '@shared/common';
import {
  useAuthQuery,
  useLMSCourses,
  useSoftDeleteCourse,
} from '@shared/react';
import {
  PageHeader,
  Card,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Button,
  message,
  Skeleton,
  Tooltip,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { CirclePicker } from 'react-color';
import { useHistory, useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import authHeader from '../../services/auth-header';
import 'suneditor/src/assets/css/suneditor.css';
import PrimaryButton from '@components/V3/Utils/PrimaryButton';
import { COLORS } from '../../v3/global';
import NextMeeting from '@components/V3/NextMeeting';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CourseCard from '@components/V3/CourseCard';
import CoursePictureEditor from '@components/V3/CoursePictureEditor';
import PreviewStudentProgressCard from '@components/V3/CoursePictureEditor/preview-student-progress-card';

const CourseDetailsEditorPage = () => {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [lmsCourseId, setLmsCourseId] = useState('');
  const [lmsCoursePrice, setLmsCoursePrice] = useState(0);
  const [abbreviation, setAbbreviation] = useState('');
  const [isFree, setIsFree] = useState(true);
  const [isMentorless, setIsMentorless] = useState(false);
  const [country, setCountry] = useState('');
  const [color, setColor] = useState('#000000');

  const [isPublic, setIsPublic] = useState(true);
  const [details, setDetails] = useState('');
  const [youtubeId, setYoutubeId] = useState('');
  const [price, setPrice] = useState<any>(0);
  const [checklist, setChecklist] = useState('');

  const [courseImage, setCourseImage] = useState<any>(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const handleImageUploaded = () => {
    setImageUploaded(!imageUploaded);
  };

  const { id }: any = useParams();

  const history = useHistory();

  const currentDate = new Date();

  const { data: templates }: any = useAuthQuery(
    checklistApis.getAllChecklistTemplates.endpoint
  );

  // LMS courses
  const { data: lmsCourses } = useLMSCourses();

  const getCourseData = () => {
    setCourseImage(null);
    axios
      .get(`/api/courses/${id}`, { headers: authHeader() })
      .then((response) => {
        setTitle(response.data.title);
        setSubtitle(response.data.subtitle);
        setLmsCourseId(response.data.lmsCourseId);
        setLmsCoursePrice(response.data?.lmsCoursePrice ?? 0);
        setAbbreviation(response.data?.abbreviation);
        setIsFree(response.data.isFree);
        setIsMentorless(response.data.isMentorless);
        setCountry(response.data.country);
        setColor(response.data.color);
        setIsPublic(response.data.isPublic);
        setDetails(response.data.details);
        setYoutubeId(response.data.youtubeId);
        setPrice(response.data.price);
        setChecklist(response.data.checklist);
        setCourseImage(response?.data?.headerImage ?? null);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        message.error(error.response?.data?.message || error?.message);
      });
  };

  const postChanges = () => {
    const changes = {
      title,
      subtitle,
      lmsCourseId,
      lmsCoursePrice,
      abbreviation,
      isFree,
      isMentorless,
      country,
      color,
      isPublic,
      details,
      checklist,
      youtubeId,
      price,
    };

    axios
      .post(`/api/course/edit`, { id, changes }, { headers: authHeader() })
      .then((response) => message.success('Course updated successfully'))
      .catch((err) => {
        message.error(err.response?.data?.message || err.message);
        console.error(err);
      });
  };

  const { mutate: deleteCourse } = useSoftDeleteCourse(id, {
    onSuccess: () => {
      message.success('Course deleted successfully');
      history.push('/admin/manage-courses');
    },
  });

  const uploadCourseImage = (formData: any) => {
    console.log('file formData: ', formData);
    axios
      .post(`/api/v2/course/upload-image/${id}`, formData, {
        headers: {
          ...authHeader(),
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log('Upload successful:', response.data);
      })
      .catch((error) => {
        console.error('Upload failed:', error);
      });
  };

  useEffect(() => {
    getCourseData();
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setImageLoading(false);
  //   }, 1000);
  // }, []);

  if (loading)
    return (
      <div>
        <div>
          <PageHeader ghost={false} title="Manage Course"></PageHeader>
        </div>

        <Card style={{ margin: 15, minHeight: 300 }}>
          <Skeleton active />
        </Card>
      </div>
    );

  return (
    <div>
      <div>
        <PageHeader
          onBack={() => history.goBack()}
          ghost={false}
          title="Manage Course"
          extra={[
            <PrimaryButton
              color={COLORS.ERROR}
              onClick={() => {
                deleteCourse({});
              }}
              style={{ marginLeft: 20 }}
            >
              Delete
            </PrimaryButton>,
            <Button onClick={postChanges}>Save</Button>,
          ]}
        ></PageHeader>
      </div>

      <Card style={{ margin: 15, minHeight: 300 }}>
        <Input
          placeholder="Title"
          value={title}
          name="title"
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        <Input
          placeholder="Subtitle"
          value={subtitle}
          name="subtitle"
          onChange={(e) => setSubtitle(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        <p style={{ color: 'red' }}>LMS Course</p>
        <Select
          style={{ width: 400, marginBottom: 10 }}
          showSearch
          onChange={(val) => {
            setLmsCourseId(val);
            setLmsCoursePrice(lmsCourses?.find((c) => c.id == val)?.price ?? 0);
          }}
          value={lmsCourseId}
        >
          <Select.Option value="">No LMS course selected</Select.Option>
          {(lmsCourses ?? []).map((course: LMSCourse) => (
            <Select.Option value={course.id} key={course.id}>
              {course.title} {course?.price ? `${course.price}$` : 'Free'}
            </Select.Option>
          ))}
        </Select>
        <Input
          placeholder="(Optional) YouTube Presentation Video ID"
          value={youtubeId}
          name="youtubeId"
          onChange={(e) => setYoutubeId(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        <Checkbox
          checked={isFree}
          onChange={(e) => setIsFree(e.target.checked)}
          style={{ marginBottom: 10 }}
        >
          <p>Is free (check even if only a few lessons are free)</p>
        </Checkbox>
        <Checkbox
          checked={isMentorless}
          onChange={(e) => setIsMentorless(e.target.checked)}
          style={{ marginBottom: 10 }}
        >
          <p>Is Mentorless</p>
        </Checkbox>
        <Checkbox
          checked={isPublic}
          onChange={(e) => setIsPublic(e.target.checked)}
          style={{ marginBottom: 10 }}
        >
          <p>Is public (appears in catalog)</p>
        </Checkbox>
        <br />
        <span style={{ color: COLORS.TEXT_PRIMARY }}>Checklist template: </span>
        <Select
          showSearch
          style={{ width: 200 }}
          onChange={(val) => setChecklist(val)}
          value={checklist}
          filterOption={(input, option: any) =>
            option &&
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Select.Option value="">No template</Select.Option>
          {templates &&
            templates.map((template: any) => (
              <Select.Option key={template._id} value={template._id}>
                {template.title}
              </Select.Option>
            ))}
        </Select>
        <span style={{ color: COLORS.TEXT_PRIMARY }}>Price: </span>
        <InputNumber
          value={price}
          formatter={(value) =>
            `${value} €`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value: any) => value && value.replace(/\$\s?|(,*)/g, '')}
          onChange={setPrice}
        />
        <span style={{ marginLeft: 10, color: COLORS.TEXT_PRIMARY }}>
          Country:{' '}
        </span>
        <Select
          onChange={(val) => setCountry(val)}
          value={country}
          style={{ marginBottom: 10 }}
        >
          <Select.Option value="">No targeted country</Select.Option>
          <Select.Option value="US">US</Select.Option>
          <Select.Option value="UK">UK</Select.Option>
          <Select.Option value="EU">EU</Select.Option>
        </Select>
        <br />
        <p>Details / Description:</p>
        <SunEditor onChange={setDetails} defaultValue={details} />
        <br />
        <p style={{ marginBottom: 2 }}>Color:</p>
        <CirclePicker
          colors={[COLORS.PRIMARY, COLORS.PURPLE, COLORS.GOLD]}
          color={color}
          onChange={(c) => setColor(c.hex)}
        />
        <div style={{ marginTop: 10, width: 'fit-content', marginBottom: 2 }}>
          <Tooltip
            title={
              <div>
                <p>Naming convention:</p>
                <br />
                <p>Eg: For Bocconi simply use a B</p>
                <br />
                <p>
                  Eg: For CoachMe (COUNTRY) use a 2 letter combination (US, UK,
                  EU, FR etc.)
                </p>
                <br />
                <p>
                  Eg: For Exam Prep use the exam abbreviation (LNAT, SAT, CAE)
                </p>
              </div>
            }
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <QuestionCircleOutlined
                style={{ fontSize: '12px', marginRight: 2 }}
              />{' '}
              <p>Abbreviation: </p>
            </div>
          </Tooltip>
        </div>

        <Input
          placeholder="Abbreviation"
          value={abbreviation}
          name="abbreviation"
          onChange={(e) => setAbbreviation(e.target.value)}
          style={{ marginBottom: 10 }}
          maxLength={5}
        />
        <p style={{ marginTop: 10, marginBottom: 2 }}>Meeting Card Preview:</p>
        <div style={{ maxWidth: '400px' }}>
          <NextMeeting
            isPreview={true}
            meeting={{
              _id: 'id placeholder',
              name: 'name placeholder',
              currentSession: 1,
              totalSessions: 10,
              enrollment: {
                _id: 'id placeholder',
                color: color,
                title: 'title placeholder',
                abbreviation: abbreviation,
                courseId: 'id placeholder',
                course: {
                  title: title,
                },
              },
              mentor: {
                _id: 'id placeholder',
                fullName: 'mentor fullname placeholder',
              },
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              students: [],
              status: 0,
              startDate: moment(currentDate).toString(),
              ended: false,
              attended: {
                mentor: false,
                student: false,
              },
              feedback: [
                {
                  rating: 5,
                  message: 'message placeholder',
                  student: 'message placeholder',
                },
              ],

              mentorFeedback: {
                message: 'message placeholder',
              },
              createdAt: 'created date placeholder',
              updatedAt: 'updated at placeholder',
            }}
            otherName={'John Doe'}
          />
        </div>
        <div
          style={{
            marginTop: 40,
            marginBottom: 8,
          }}
        >
          <div style={{ marginBottom: 8 }}>
            <div style={{ marginBottom: 32 }}>
              <p style={{ marginBottom: 8 }}>Course Cards Preview:</p>

              <div style={{ marginBottom: 8, maxWidth: '400px' }}>
                <CourseCard
                  title={title}
                  image={
                    courseImage?.['160x160'] ? courseImage['160x160'] : null
                  }
                />
              </div>
              <div style={{ marginBottom: 8, maxWidth: '400px' }}>
                <PreviewStudentProgressCard
                  courseTitle={title}
                  image={
                    courseImage?.['470x320'] ? courseImage['470x320'] : null
                  }
                />
              </div>
            </div>
            <CoursePictureEditor
              id={id}
              refetchData={getCourseData}
              handleImageUploaded={handleImageUploaded}
              title={title}
            />
          </div>
        </div>
        <Button onClick={postChanges} style={{ marginTop: 20 }}>
          Save changes
        </Button>
      </Card>
    </div>
  );
};

export default CourseDetailsEditorPage;
