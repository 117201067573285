import { ReactComponent as CompletedIcon } from '@assets/icons/V4/empty-states/completed-ongoing-courses-icon.svg';
import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/empty-icon.svg';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import Button from '@components/V4/Button';
import CourseProgressCard from '@components/V4/CourseProgressCard';
import { COURSES } from '@routes';
import { Enrollment } from '@shared/common';
import { useLoginAndRedirectToCourse } from '@shared/react';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './your-courses.styles.scss';

type Props = {
  enrollments: Enrollment[];
};

const YourCoursesStudent = ({ enrollments }: Props) => {
  const history = useHistory();

  const { data: URL, mutate: redirectToLMS } = useLoginAndRedirectToCourse({
    onSuccess: (data) => {
      if (data?.url) {
        setTimeout(() => {
          window.open(data.url, '_blank');
        });
      }
    },
  });

  const [showAll, setShowAll] = useState(false);
  const filteredEnrollments = enrollments.filter(
    (enrollment) => !(enrollment.ended === true)
  );

  const itemsPerPage = 6;
  const slicedCourses = showAll
    ? filteredEnrollments
    : filteredEnrollments?.slice(0, itemsPerPage);

  const displayEnrollments = () => {
    if (
      !!enrollments &&
      enrollments?.every((enrollment) => enrollment?.ended === true)
    )
      return (
        <EmptyStateCard
          icon={<CompletedIcon />}
          text={`Congratulations on completing all your courses! 
Stay tuned for new opportunities.`}
        />
      );
    else if (
      !!enrollments &&
      !enrollments?.every((enrollment) => enrollment?.ended === true)
    )
      return (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 largeLaptop:grid-cols-3">
          {slicedCourses?.map(
            (course) =>
              !(course.ended && course.ended === true) && (
                <CourseProgressCard
                  className="h-full"
                  enrollment={course}
                  key={course._id}
                  onClick={() => {
                    if (course?.course?.lmsCourseId) {
                      redirectToLMS({
                        courseId: course.course.lmsCourseId,
                      });
                    } else {
                      history.push(`${COURSES}/${course.course._id}`);
                    }
                  }}
                />
              )
          )}
        </div>
      );
    else {
      return (
        <EmptyStateCard
          icon={<EmptyStateIcon />}
          text={`No courses available yet. 
  Stay tuned for updates!`}
        />
      );
    }
  };

  return (
    <div>
      <div className="your-courses-header-container">
        <div className="your-courses-section-title">
          <SectionTitle title="Ongoing Courses" />
        </div>

        {enrollments && enrollments?.length > itemsPerPage && (
          <Button
            variant="text"
            className="w-max"
            buttonText={showAll ? 'See Less' : 'See All'}
            onClick={() => setShowAll((prev) => !prev)}
          />
        )}
      </div>

      {displayEnrollments()}
    </div>
  );
};

export default YourCoursesStudent;
