import classNames, { ArgumentArray } from 'classnames';
import moment, { Moment } from 'moment';
import { extendTailwindMerge } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [{ text: ['xxs', 'xsm'] }],
    },
  },
});

export const mergeClassNames = (...classes: ArgumentArray) =>
  customTwMerge(classNames(...classes));

export const getValidMoment = (date: string | null): Moment | null => {
  const parsedDate = moment(date);
  return parsedDate.isValid() ? parsedDate : null;
};

export const getStartDateValue = (value?: Moment) =>
  value?.startOf('day') || moment().subtract(1, 'month');

export const getEndDateValue = (value?: Moment) =>
  value?.endOf('day') || moment().add(1, 'month');

export const isMobile = () =>
  /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
