import { ReactComponent as EmptyProfilePic } from '@assets/icons/V4/empty-profile-pic.svg';
import MentorPlaceholderAvatar from '@assets/icons/mentor-placeholder-avatar.png';
import USBigPrimary from '@assets/images/us_big_1.jpg';
import USBigPurple from '@assets/images/us_big_2.jpg';
import USBigGold from '@assets/images/us_big_3.jpg';
import { Enrollment } from '@shared/types';
import { mergeClassNames } from '../../utils/helpers';
import moment from 'moment';

interface CourseProgressCardProps {
  enrollment: Partial<Enrollment>;
  className?: string;
  onClick?: () => void;
}

const CourseProgressCard = (props: CourseProgressCardProps) => {
  const { enrollment, className, onClick } = props;

  const { course } = enrollment;

  const progressPercentage = `${
    enrollment.noOfSessions === 0
      ? 0
      : ((enrollment.noOfCompletedSessions ?? 0) /
          (enrollment.noOfSessions ?? 0)) *
        100
  }%`;

  const getCourseBackground = () => {
    if (course?.headerImage?.['470x320']) {
      return course?.headerImage['470x320'];
    } else if (
      course?.title?.toLowerCase()?.includes('coach') ||
      course?.title?.toLowerCase()?.includes('training')
    ) {
      return USBigPrimary;
    } else if (course?.title?.toLowerCase()?.includes('academic')) {
      return USBigPurple;
    } else {
      return USBigGold;
    }
  };

  return (
    <div
      className={mergeClassNames(
        'group flex flex-col gap-3 rounded-xl cursor-pointer bg-surfaceObject min-h-72',
        className
      )}
      onClick={onClick}
    >
      <div
        style={{ backgroundImage: `url(${getCourseBackground()})` }}
        className="bg-cover bg-no-repeat bg-center h-36 rounded-xl relative"
      >
        <div className="absolute flex items-center justify-center bottom-2 left-2 py-1 px-2 rounded-full bg-customGrey bg-opacity-35">
          <span className="text-xxs text-white">{`${
            enrollment?.startDate
              ? moment(enrollment.startDate).format("MMM 'YY")
              : 'N/A'
          } - ${
            enrollment?.estimatedEndDate
              ? moment(enrollment.estimatedEndDate).format("MMM 'YY")
              : 'N/A'
          }`}</span>
        </div>
      </div>

      <div className="flex flex-col gap-3 px-3 pb-3">
        <p
          className={mergeClassNames(
            'font-raleway font-bold leading-5 min-h-9 group-hover:text-customGold',
            {
              'group-hover:text-customPrimary':
                course?.title?.toLowerCase()?.includes('coach') ||
                course?.title?.toLowerCase()?.includes('training'),
              'group-hover:text-customLila': course?.title
                ?.toLowerCase()
                ?.includes('academic'),
            }
          )}
        >
          {course?.title}
        </p>

        {!(enrollment?.course?.isMentorless || !enrollment.noOfSessions) && (
          <>
            <div className="flex gap-3 items-center">
              {enrollment?.mentor?._id ? (
                <img
                  src={MentorPlaceholderAvatar}
                  alt="mentor"
                  className="h-7 w-7 rounded-full"
                />
              ) : (
                <EmptyProfilePic className="text-customGrey" />
              )}
              <p className="text-xxs text-customGrey font-semibold">
                {enrollment?.mentor?._id
                  ? enrollment?.mentor?.fullName
                  : 'Waiting for Mentor'}
              </p>
            </div>
            <div>
              <div className="w-full h-2 rounded-full bg-surfaceHover bg-opacity-50">
                <div
                  style={{ width: progressPercentage }}
                  className={mergeClassNames(
                    `rounded-full h-full bg-customGold`,
                    {
                      'bg-customPrimary':
                        course?.title?.toLowerCase()?.includes('coach') ||
                        course?.title?.toLowerCase()?.includes('training'),
                      'bg-customPurple': course?.title
                        ?.toLowerCase()
                        ?.includes('academic'),
                    }
                  )}
                ></div>
              </div>
              <div className="flex gap-1.5 items-center">
                <p className="text-xxs text-customGrey">
                  <span className="font-bold">{progressPercentage}</span>{' '}
                  Completed
                </p>
                <p className="text-customGrey"> • </p>
                <p className="text-xxs text-customGrey">
                  <span className="font-bold">
                    {enrollment.noOfCompletedSessions}/{enrollment.noOfSessions}
                  </span>{' '}
                  Meetings done
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CourseProgressCard;
