import { ReactComponent as PlusIcon } from '@assets/icons/plus-icon.svg';
import IconButton from '@components/V4/IconButton';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';

interface ResumeSectionProps extends BasicProps {
  title: string;
  isButtonActive?: boolean;
  onClick?: () => void;
}

const StudentMainSection = (props: ResumeSectionProps) => {
  const { children, title, onClick, isButtonActive } = props;
  return (
    <>
      <div className="flex justify-between">
        <h3 className="font-raleway font-bold">{title}</h3>
        {!!onClick && (
          <IconButton
            onClick={onClick}
            variant="ghost"
            className="mr-4 tablet:mr-8"
            icon={
              <PlusIcon
                className={mergeClassNames({
                  'w-4 h-4  text-customGrey m-auto': true,
                  'text-customPrimary': isButtonActive,
                })}
              />
            }
          />
        )}
      </div>
      {children}
    </>
  );
};

export default StudentMainSection;
