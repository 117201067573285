import React from 'react';

interface ChatEntryProps {
  name: string;
  timestamp: string;
  message: string;
}

const ChatEntry = (props: ChatEntryProps) => {
  const { name, timestamp, message } = props;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <p className="text-customGrey text-xs font-bold">{name}</p>
        <p className="text-customGrey text-xs leading-4 font-light">
          {timestamp}
        </p>
      </div>
      <p className="text-white text-xs leading-5">{message}</p>
    </div>
  );
};

export default ChatEntry;
